import { Injectable } from "@angular/core";
import { Storage } from "aws-amplify";
import { saveAs } from "file-saver";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { listOfImagesExtensions } from "src/app/core/classes/ImagesExtensions";
import { tableCorrespondance } from "src/app/core/classes/ImagesExtensions";

@Injectable({
  providedIn: "root",
})
export class FileManagementService {
  /** URL de l'API */
  apiURL = environment.apiUrl;
  API_path = "sites";

  constructor(private httpClient: HttpClient) {}

  /**
   * Get data for the dashboard component
   * @param SITE_NAME Name of the site
   */
  getDashboardData(SITE_NAME: string) {
    Storage.get(`SITES/${SITE_NAME}/DATA/csv/Real_Data_2020_France.csv`, {
      level: "public",
      download: true,
    })
      .then((result) => {
        console.log(result["LastModified"]);
        let lastModifiedDate = result["LastModified"].toLocaleDateString();
        saveAs(
          result["Body"],
          environment.csvFilename + "-" + lastModifiedDate + ".csv"
        );
      })
      .catch((err) => console.log(err));
  }

  /**
   * Download excel file from Dashboard Analytics
   * @param fileName File name
   * @param startDate Start date
   * @param endDate End date
   * @returns file downloaded
   */
  downloadFile(sourceFileName: string, targetFileName: string) {
    Storage.get(`${sourceFileName}`, {
      level: "public",
      download: true,
    })
      .then((result) => {
        saveAs(result["Body"], targetFileName);
      })
      .catch((err) => console.log(err));
  }

  /**
   * Upload a file in the S3 bucket of the site
   * @param file File we want to upload
   * @param lang Languages if needed
   * @param format Format of the file
   * @param site Name of the site
   * @param name Name of the file
   * @returns Return a boolean if upload is done or not
   */
  uploadFile(
    file: any,
    lang: string,
    format: string,
    site: string,
    name?: string
  ) {
    return new Promise((resolve) => {
      if (format === "csv") {
        Storage.put(`SITES/${site}/DATA/csv/${name}`, file, {
          level: "public",
          contentType: "text/csv",
        })
          .then((result) => {
            console.log(result);
            resolve(result);
          })
          .catch((err) => console.log(err));
      } else if (format === "json") {
        Storage.put(`SITES/${site}/DATA/i18n/${lang}.json`, file, {
          level: "public",
          contentType: "application/json",
        })
          .then((result) => {
            console.log(result);
            resolve(result);
          })
          .catch((err) => console.log(err));
      } else if (format === "pdf") {
        Storage.put(`SITES/${site}/DATA/pdf/${name}`, file, {
          level: "public",
          contentType: "application/pdf",
        })
          .then((result) => {
            console.log(result);
            resolve(result);
          })
          .catch((err) => console.log(err));
      } else if (format === "ics") {
        Storage.put(`SITES/${site}/DATA/ics/${name}`, file, {
          level: "public",
          contentType: "text/calendar",
        })
          .then((result) => {
            console.log(result);
            resolve(result);
          })
          .catch((err) => console.log(err));
      } else if (listOfImagesExtensions.includes(format)) {
        Storage.put(`SITES/${site}/IMG/${name}`, file, {
          level: "public",
          contentType: tableCorrespondance.get(format),
        })
          .then((result) => {
            console.log(result);
            resolve(result);
          })
          .catch((err) => console.log(err));
      } else if (format === "null") {
        Storage.put(`SITES/${site}/${name}`, file, {
          level: "public",
          contentType: tableCorrespondance.get(name.split(".").pop()),
        })
          .then((result) => {
            console.log(result);
            resolve(result);
          })
          .catch((err) => console.log(err));
      } else {
        resolve(false);
      }
    });
  }

  /**
   * Function to remove a file in the S3 Bucket on a specific site
   * @param site Name of the site
   * @param name Name of the file
   * @returns return a promise
   */
  removeFile(site: string, name: string) {
    return new Promise((resolve) => {
      Storage.remove(`SITES/${site}/${name}`, {
        level: "public",
      })
        .then((result) => {
          console.log(result);
          resolve(result);
        })
        .catch((err) => console.log(err));
    });
  }

  /**
   * Call /preview endpoint of the API
   * @param site Name of the site
   * @returns return a promise
   */
  previewModification(site: string, miscMetadata: any) {
    return this.httpClient
      .post(`${this.apiURL}/${this.API_path}/${site}/preview`, miscMetadata)
      .toPromise();
  }

  /**
   * Call /publish endpoint of the API
   * @param site Name of the site
   * @returns return a promise
   */
  publishModification(site: string) {
    return this.httpClient
      .post(`${this.apiURL}/${this.API_path}/${site}/publish`, null)
      .toPromise();
  }
}
