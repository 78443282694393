import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class JsonSecondaryLanguageService {
  constructor() {}

  async getJSON(formValue, value_SECONDARY) {
    var json_secondary = {
      pages: formValue.pages_secondary,
      language: value_SECONDARY.language,
      graphics: {
        menuBottomLogoPath: formValue.secondaryLogo_09_filename_secondary,
        menuTopLogoPath: formValue.menuLogo_09_filename_secondary,
        headerLogoPath: formValue.headerLogo_10_filename_secondary,
      },
      legalNotices: {
        legalInformation: value_SECONDARY.legalNotices.legalInformation,
        privacyPolicy: value_SECONDARY.legalNotices.privacyPolicy,
      },
      menu: {
        menuLinks: formValue.links_menu_secondary,
        bottomText: formValue.internalUsage_09_secondary,
      },
      contentBlockingModal: {
        title: formValue.contentBlockingModalTitle_secondary,
        content: formValue.contentBlockingModalContent_secondary,
        accept: formValue.contentBlockingModalAccept_secondary,
        decline: formValue.contentBlockingModalDecline_secondary,
      },
      passwordModal: {
        title: formValue.passwordModalTitle_secondary,
        content: formValue.passwordModalContent_secondary,
      },
      map: {
        startDateCampaign: formValue.campaignDate_04_primary[0].getTime(),
        endDateCampaign: formValue.campaignDate_04_primary[1].getTime(),
        dataPath: formValue.mapData_04_filename_primary,
        activated: formValue.activateDashboardCheck_04_primary ? true : false,
        title: formValue.subtitle_04_secondary,
        legend1: formValue.label1_04_secondary,
        legend2: formValue.label2_04_secondary,
        informationAboutData: formValue.information_data_content_04_secondary,
      },
    };
    return json_secondary;
  }
}
