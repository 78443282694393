import { Component } from "@angular/core";

@Component({
  selector: "aac-cookie-information",
  templateUrl: "./cookie-information.component.html",
  styleUrls: ["./cookie-information.component.scss"],
})
export class CookieInformationComponent {
  constructor() {}
}
