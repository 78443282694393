import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class JsonPrimaryLanguageService {
  constructor() {}

  async getJSON(formValue, value_PRIMARY) {
    var json_primary = {
      pages: formValue.pages_primary,
      language: value_PRIMARY.language,
      graphics: {
        menuBottomLogoPath: formValue.secondaryLogo_09_filename_primary,
        menuTopLogoPath: formValue.menuLogo_09_filename_primary,
        headerLogoPath: formValue.headerLogo_10_filename_primary,
      },
      legalNotices: {
        legalInformation: value_PRIMARY.legalNotices.legalInformation,
        privacyPolicy: value_PRIMARY.legalNotices.privacyPolicy,
      },
      menu: {
        menuLinks: formValue.links_menu_primary,
        bottomText: formValue.internalUsage_09_primary,
      },
      contentBlockingModal: {
        title: formValue.contentBlockingModalTitle_primary,
        content: formValue.contentBlockingModalContent_primary,
        accept: formValue.contentBlockingModalAccept_primary,
        decline: formValue.contentBlockingModalDecline_primary,
      },
      passwordModal: {
        title: formValue.passwordModalTitle_primary,
        content: formValue.passwordModalContent_primary,
      },
      map: {
        startDateCampaign: formValue.campaignDate_04_primary[0].getTime(),
        endDateCampaign: formValue.campaignDate_04_primary[1].getTime(),
        dataPath: formValue.mapData_04_filename_primary,
        activated: formValue.activateDashboardCheck_04_primary ? true : false,
        title: formValue.subtitle_04_primary,
        legend1: formValue.label1_04_primary,
        legend2: formValue.label2_04_primary,
        informationAboutData: formValue.information_data_content_04_primary,
      },
    };
    return json_primary;
  }
}
