import { Injectable } from "@angular/core";
import bcrypt from "bcryptjs";

@Injectable({
  providedIn: "root",
})
export class QRCodePasswordService {
  private saltRounds: number;
  constructor() {
    this.saltRounds = 10;
  }

  /** Function to hash password with bcrypt */
  async hashPassword(password: string) {
    let salt = await bcrypt.genSalt(this.saltRounds);
    return bcrypt.hash(password, salt);
  }

  /** Function to compare password with bcrypt */
  async validatePassword(password: string, hash: string) {
    return await bcrypt.compare(password, hash);
  }
}
