import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { env } from "process";
import { ISubscription } from "rxjs-compat/Subscription";
import { ALERT_MESSAGE } from "src/app/core/classes/AlertMessage";
import { ROUTES } from "src/app/core/classes/Routes";
import { HttpclientService } from "src/app/core/services/httpclient.service";
import { RoutesService } from "src/app/core/services/routes.service";
import { SitesService } from "src/app/core/services/sites.service";
import { SurveysService } from "src/app/core/services/surveys.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "aac-survey",
  templateUrl: "./survey.component.html",
  styleUrls: ["./survey.component.scss", "../public.scss"],
})
export class SurveyComponent implements OnInit, OnDestroy {
  inputPath: string = environment.inputPath;

  currentLanguage: string = "";

  ALERT_MESSAGE: ALERT_MESSAGE = {
    TYPE_OF_MESSAGE: null,
    TYPE_OF_ACTION: null,
    MESSAGE: null,
    IS_DISPLAYED: null,
  };

  ROUTES: ROUTES = {
    IS_PUBLIC: false,
    IS_PRIVATE: false,
    IS_GLOBAL: false,
    ROUTE: null,
    PARAMS: null,
    MANDATORY_PARAMS: [],
    QUERY_PARAMS: null,
  };
  GA_SOURCE: string = null;
  GA_MEDIUM: string = null;
  SITE: any = {};
  SITE_NAME: string = "";
  PAGE_CONTENT: any;

  SITE_NAME_FORM: string = "";

  surveyForm: FormGroup;
  submitted: boolean = false;
  formIsLoaded: boolean = false;

  colorHeader: any;

  GENERAL: any;

  FORM_ID: string = null;
  FORM;
  ARCHIVED_QUESTIONS = [];

  errorMessage: string = null;

  currentRoutesSubscribe: ISubscription;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private _routesService: RoutesService,
    private _sitesService: SitesService,
    private _surveysService: SurveysService,
    private _httpClient: HttpclientService
  ) {
    /** Get routes information then get site information*/
    this.currentRoutesSubscribe = this._routesService.currentRoutes.subscribe(
      (routes) => {
        this.ROUTES = routes;
        console.log(routes);
        if (routes.PARAMS) {
          console.log(routes.PARAMS);
          if (routes.PARAMS["site"] != undefined)
            var site = routes.PARAMS["site"];
          if (routes.PARAMS["id"] != undefined) var id = routes.PARAMS["id"];
          site ? (this.SITE_NAME = site.toUpperCase()) : (this.SITE_NAME = "");
          id ? (this.FORM_ID = id) : (this.FORM_ID = null);
          if (this.FORM_ID === null)
            this.errorMessage = "You need to set a form ID";
          //je récupére la couleur quand je suis sur survey
          this.colorHeader = sessionStorage.getItem("colorHeader");
          console.log("la couleur survey colorHeadersg", this.colorHeader);
        }
        if (sessionStorage.getItem("GA_SOURCE"))
          this.GA_SOURCE = sessionStorage.getItem("GA_SOURCE");
        if (sessionStorage.getItem("GA_MEDIUM"))
          this.GA_MEDIUM = sessionStorage.getItem("GA_MEDIUM");
        console.log(this.GA_SOURCE, this.GA_MEDIUM);
      }
    );
    /** Get site information */
    this._sitesService
      .getSite(this.SITE_NAME)
      .then((res) => {
        this.SITE = JSON.parse(JSON.stringify(res));
        console.log(this.SITE);
      })
      .catch((err) => this.callbackHTTPClient(err));
  }

  ngOnInit() {
    this.SITE_NAME_FORM = this.SITE_NAME;

    /** If site name include -PREVIEW we remove it (form doesn't exists in preview) */
    if (this.SITE_NAME_FORM.includes("-PREVIEW")) {
      this.SITE_NAME_FORM = this.SITE_NAME_FORM.replace("-PREVIEW", "");
    }
    /** Get form value */
    this._surveysService
      .getForm(this.SITE_NAME_FORM, this.FORM_ID)
      .then((value) => {
        console.info(value);
        this.FORM = JSON.parse(JSON.stringify(value));
        if (Object.keys(this.FORM).length <= 0) {
          console.error("Form is empty");
          return;
        }
        this.formIsLoaded = true;
        this.initForms();
      })
      .catch((err) => this.callbackHTTPClient(err));
  }

  ngOnDestroy() {
    console.log("ngOnDestroy is executing...");
    this.currentRoutesSubscribe.unsubscribe();
  }

  /** Init different forms */
  initForms() {
    console.log(JSON.parse(JSON.stringify(this.FORM)));
    //We create the Reactive Forms that will 'host' the questions
    this.surveyForm = this.formBuilder.group({
      uuid: [this.FORM.uuid],
      site: [this.SITE_NAME],
      location1: [this.GA_SOURCE],
      location2: [this.GA_MEDIUM],
      title: [this.FORM.title],
      description: [this.FORM.description],
      end_message: [this.FORM.end_message],
      creation_date: [this.FORM.creation_date],
      questions: new FormArray([]),
    });
    //We keep only ACTIVATED questions
    this.FORM.questions = this.FORM.questions.filter(
      (QUESTION) => QUESTION.status === "ACTIVATED"
    );
    //Then we add these questions to the Reactive Forms
    this.FORM["questions"].forEach((question) => {
      this.addQuestion(question);
    });
    //Last check that all question is well ACTIVATED
    for (let i = 0; i < this.questions().controls.length; i++) {
      if (this.questions().controls[i]["value"]["status"] != "ACTIVATED") {
        this.removeQuestion(i);
      }
    }
    console.log(this.surveyForm);
  }

  /** ---- Question Management ---- */
  questions(): FormArray {
    return this.surveyForm.get("questions") as FormArray;
  }
  addQuestion(question: any) {
    var newQuestion: FormGroup;
    if (question.type === "SIMPLE_CHOICE") {
      newQuestion = this.formBuilder.group({
        uuid: [question.uuid],
        title: [question.title],
        type: [question.type],
        image: [question.image],
        status: [question.status],
        options: [question.answers_options],
        select_option: [null, [Validators.required]],
      });
      this.questions().push(newQuestion);
    } else if (question.type === "MULTIPLE_CHOICE") {
      newQuestion = this.formBuilder.group({
        uuid: [question.uuid],
        title: [question.title],
        type: [question.type],
        image: [question.image],
        status: [question.status],
        answers_options: new FormArray([]),
      });
      this.questions().push(newQuestion);
      question["answers_options"].forEach((option) => {
        this.addOption(this.questions().length - 1, option);
      });
    } else {
      console.error("Error: Type is not allowed");
    }
  }
  removeQuestion(i: number) {
    this.ARCHIVED_QUESTIONS.push(this.questions().controls[i]["value"]);
    this.questions().removeAt(i);
  }
  /** ---- Question Management ---- */
  /** ---- Options Management ---- */
  options(index: number): FormArray {
    return this.surveyForm
      .get("questions")
      ["controls"][index].get("answers_options") as FormArray;
  }
  addOption(index: number, option: any) {
    const newOption: FormGroup = this.formBuilder.group({
      uuid: [option.uuid],
      label: [option.label],
      selected: [false, [Validators.required]],
    });
    this.options(index).push(newOption);
  }
  removeOption(index: number, i: number) {
    this.options(index).removeAt(i);
  }
  /** ---- Options Management ---- */

  /** onSubmit function */
  onSubmit() {
    this.submitted = true;
    console.log(this.surveyForm);
    var surveyFormValue = this.surveyForm.value;
    console.log(surveyFormValue);
    console.log(this.ARCHIVED_QUESTIONS);

    var tempErrorMessage = "All questions are mandatory";

    /** For each questions, we check if questions type is MULTIPLE_CHOICE
     * If it's MULTIPLE_CHOICE, we check if at least one options is selected with function questionMultipleIsValid */
    for (let i = 0; i < surveyFormValue["questions"].length; i++) {
      if (surveyFormValue["questions"][i]["type"] === "MULTIPLE_CHOICE") {
        var questionIsValid = this.questionMultipleIsValid(i, surveyFormValue);
        if (questionIsValid.isValid === false) {
          this.ALERT_MESSAGE.IS_DISPLAYED = true;
          this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "errorModal";
          this.ALERT_MESSAGE.MESSAGE = tempErrorMessage;
          return;
        }
      }
    }

    //stop here if form is invalid
    if (this.surveyForm.invalid) {
      this.ALERT_MESSAGE.IS_DISPLAYED = true;
      this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "errorModal";
      this.ALERT_MESSAGE.MESSAGE = tempErrorMessage;
      return;
    }

    //Transform ReactiveForm for API body
    var ANSWER = {};
    ANSWER["location1"] = surveyFormValue["location1"] || "";
    ANSWER["location2"] = surveyFormValue["location2"] || "";
    ANSWER["questions"] = [];
    for (let i = 0; i < surveyFormValue.questions.length; i++) {
      ANSWER["questions"][i] = {
        uuid: surveyFormValue.questions[i]["uuid"],
        answers_options: [],
      };
      //Check if question is SIMPLE_CHOICE or MULTIPLE_CHOICE
      if (surveyFormValue.questions[i]["type"] === "SIMPLE_CHOICE") {
        ANSWER["questions"][i]["answers_options"].push(
          surveyFormValue.questions[i]["select_option"]
        ); //If the options is selected, we push it to answer_options array
      } else if (surveyFormValue.questions[i]["type"] === "MULTIPLE_CHOICE") {
        surveyFormValue.questions[i]["answers_options"].forEach((options) => {
          if (options.selected)
            ANSWER["questions"][i]["answers_options"].push(options.uuid); //For each option we check if it's selected, we push it to answer_options array
        });
      }
    }

    console.log("Answer back to API :", ANSWER);
    console.log("Form is ok");

    /** Post answer */
    this._surveysService
      .postAnswer(this.SITE_NAME, this.FORM_ID, ANSWER)
      .then(() => {
        this.ALERT_MESSAGE.IS_DISPLAYED = true;
        this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "successModal";
        this.ALERT_MESSAGE.MESSAGE = this.FORM.end_message;
      })
      .catch((err) => this.callbackHTTPClient(err));
  }

  /**
   * Check if question with MULTIPLE_CHOICE have at least one option selected
   * @param i
   * @param surveyFormValue
   * @returns
   */
  questionMultipleIsValid(i: number, surveyFormValue: any) {
    var found: Array<Array<Boolean>> = [];
    var questionsMultiple: Array<Boolean> = [];
    found[i] = [];
    questionsMultiple[i] = false;
    for (
      let j = 0;
      j < surveyFormValue["questions"][i]["answers_options"].length;
      j++
    ) {
      if (surveyFormValue["questions"][i]["answers_options"][j]["selected"]) {
        found[i].push(true);
        j = surveyFormValue["questions"][i]["answers_options"].length;
      } else found[i].push(false);
    }
    if (found[i].includes(true)) questionsMultiple[i] = true;
    return {
      isValid: questionsMultiple[i],
      ID: i,
    };
  }

  /**
   * Navigate through components
   * @param route route to reach
   */
  navigate(route: string) {
    this.router.navigate([`/${route}/${this.SITE_NAME.toLowerCase()}`]);
  }

  /** Close modal */
  closeModal() {
    this.callbackResetModal(this.ALERT_MESSAGE);
  }

  /** Callback to close modal and reset object ALERT_MESSAGE after displaying modal */
  callbackResetModal(ALERT_MESSAGE: ALERT_MESSAGE) {
    this.resetModal();
  }

  /** Reset modal */
  resetModal() {
    this.ALERT_MESSAGE.IS_DISPLAYED = false;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = null;
    this.ALERT_MESSAGE.TYPE_OF_ACTION = null;
    this.ALERT_MESSAGE.MESSAGE = null;
  }

  /** Callback sent to {@link HTTPClientService} */
  callbackHTTPClient(error) {
    console.log(error);
    this.errorMessage;
    this.ALERT_MESSAGE.IS_DISPLAYED = true;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "errorModal";
    this.ALERT_MESSAGE.MESSAGE = error["message"];
  }
}
