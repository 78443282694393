import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { MainPageComponent } from "../public/main-page/main-page.component";
import { LegaleInformationComponent } from "./legale-information/legale-information.component";
import { CookieInformationComponent } from "./cookie-information/cookie-information.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { SurveyComponent } from "./survey/survey.component";
import { MultiPagesComponent } from "./multi-pages/multi-pages.component";

const APP_ROUTES_PUBLIC: Routes = [
  { path: "home/:site", component: MainPageComponent },
  { path: "home-map/:site", component: DashboardComponent },
  { path: "more-information/:site/:id", component: MultiPagesComponent },
  { path: "survey/:site/:id", component: SurveyComponent },
  { path: "legal/:site", component: LegaleInformationComponent },
  { path: "legal/:site/", component: LegaleInformationComponent },
  { path: "cookie/:site", component: CookieInformationComponent },
  { path: "cookie/:site/", component: CookieInformationComponent },
];

@NgModule({
  imports: [RouterModule.forChild(APP_ROUTES_PUBLIC)],
  exports: [RouterModule],
})
export class PublicRoutingModule {}
