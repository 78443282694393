import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { BarcodeFormat } from "@zxing/library";
import { HttpclientService } from "src/app/core/services/httpclient.service";
import { FormArray, Validators, FormBuilder, FormGroup } from "@angular/forms";
import * as FileSaver from "file-saver";
import { environment } from "src/environments/environment";
import { ALERT_MESSAGE } from "src/app/core/classes/AlertMessage";
import { Title } from "@angular/platform-browser";
import { RoutesService } from "src/app/core/services/routes.service";
import { SitesService } from "src/app/core/services/sites.service";
import { ROUTES } from "src/app/core/classes/Routes";
import { QR_CODES_CONFIG } from "src/app/core/classes/QRCodesConfig";
import { AdminFormService } from "src/app/core/services/admin-form.service";
import { QRCodeGenerationService } from "src/app/core/services/qrcode-generation.service";

@Component({
  selector: "aac-check-pair",
  templateUrl: "./check-pair.component.html",
  styleUrls: [
    "./check-pair.component.scss",
    "../qr-code-management.scss",
    "../../local-back-office.scss",
    "../../../private.scss",
  ],
})
export class QRCheckPairComponent implements OnInit, OnDestroy {
  canvasConfig: ElementRef;

  @ViewChild("canvasConfig", { static: false }) set contentConfig(
    contentConfig: ElementRef
  ) {
    if (contentConfig) {
      this.canvasConfig = contentConfig;
      this.initQrCodeSettings();
    }
  }

  qrCodeSettingsInitialized = false;

  ROUTES: ROUTES = {
    IS_PUBLIC: false,
    IS_PRIVATE: false,
    IS_GLOBAL: false,
    ROUTE: null,
    PARAMS: null,
    MANDATORY_PARAMS: [],
    QUERY_PARAMS: null,
  };
  SITE: any = {};
  SITE_NAME: string = "";

  ALERT_MESSAGE: ALERT_MESSAGE = {
    TYPE_OF_MESSAGE: null,
    TYPE_OF_ACTION: null,
    MESSAGE: null,
    IS_DISPLAYED: null,
  };

  QR_CODES_CONFIG: QR_CODES_CONFIG = {
    COLOR: "#FFFFFF",
    SIZE: 300,
    MARGIN: 0,
    TYPE_NUMBER: 0,
    MODE: "Byte",
    ERROR_CORRECTION_LEVEL: "Q",
    COLOR_TYPE: 1,
    GRADIENT_OPTION: {
      TYPE: "linear",
      ROTATION: 0,
      COLOR_STOPS: [
        {
          OFFSET: 0,
          COLOR: "#FFFFFF",
        },
        {
          OFFSET: 1,
          COLOR: "#FFFFFF",
        },
      ],
    },
    IMAGE: {
      IMAGE_NAME: "Logo_Elefight.png",
      IS_BACK_DOTS_HIDDEN: true,
      IMAGE_SIZE: 0.3,
      MARGIN: 0,
    },
    DOTS: {
      STYLE: "rounded",
      COLOR_TYPE: 1,
      COLOR: "#000000",
      DOTS_GRADIENT_OPTION: {
        TYPE: "linear",
        ROTATION: 0,
        COLOR_STOPS: [
          {
            OFFSET: 0,
            COLOR: "#FFFFFF",
          },
          {
            OFFSET: 1,
            COLOR: "#FFFFFF",
          },
        ],
      },
    },
    CORNERS_SQUARE: {
      COLOR: "#000000",
      STYLE: "extra-rounded",
      COLOR_TYPE: 1,
      CORNERS_SQUARE_GRADIENT_OPTION: {
        TYPE: "linear",
        ROTATION: 0,
        COLOR_STOPS: [
          {
            OFFSET: 0,
            COLOR: "#FFFFFF",
          },
          {
            OFFSET: 1,
            COLOR: "#FFFFFF",
          },
        ],
      },
    },
    CORNERS_DOTS: {
      COLOR: "#000000",
      STYLE: "dot",
      COLOR_TYPE: 1,
      CORNERS_DOTS_GRADIENT_OPTION: {
        TYPE: "linear",
        ROTATION: 0,
        COLOR_STOPS: [
          {
            OFFSET: 0,
            COLOR: "#FFFFFF",
          },
          {
            OFFSET: 1,
            COLOR: "#FFFFFF",
          },
        ],
      },
    },
  };

  SRC_IMAGE_RENDER = null;

  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;

  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];

  constraints = { audio: false, video: true };

  AutorizeMedia: boolean;
  hasDevices: boolean;
  hasPermission: boolean;

  qrResultString: string;
  qrResultUUID: string[];

  torchEnabled = false;
  torchAvailable$ = new BehaviorSubject<boolean>(false);
  tryHarder = false;

  scannerEnabled = false;
  isCameraActivated = false;

  data: string = environment.redirectUrl;

  IDtoSearch: string = "";
  search: any;
  listQRCodes: any;
  dynamicForm: FormGroup;
  submitted: boolean = false;
  generated: boolean = false;
  message: string;

  isEpidMapActivated: boolean = false;

  ITEM: any;

  landing_page_array = [];
  qrcode_init = { loc1: "", loc2: "" };

  constructor(
    private _httpClientService: HttpclientService,
    private _qrCodeGeneration: QRCodeGenerationService,
    private _routesService: RoutesService,
    private _sitesService: SitesService,
    private _httpService: HttpclientService,
    private _adminFormService: AdminFormService,
    private router: Router,
    private formBuilder: FormBuilder,
    private titleService: Title
  ) {
    this.titleService.setTitle("Elefight - QR Code Management");
    this.SRC_IMAGE_RENDER = "../../../../../assets/img/Logo_Elefight.png";

    /** Get routes information then get site information*/
    this._routesService.currentRoutes.subscribe((routes) => {
      this.ROUTES = routes;
      console.log(routes);
      if (routes.PARAMS) {
        console.log(routes.PARAMS);
        if (routes.PARAMS["site"] != undefined)
          var site = routes.PARAMS["site"];
        site ? (this.SITE_NAME = site.toUpperCase()) : (this.SITE_NAME = "");
        this.data = `${environment.redirectUrl}/${this.SITE_NAME}/qr_uuid`;
      }
    });

    this._sitesService
      .getSite(this.SITE_NAME)
      .then((value) => {
        this.SITE = JSON.parse(JSON.stringify(value));
        console.log(this.SITE);

        if (Object.keys(this.SITE).length <= 0) {
          console.error("Site doesn't exist");
          return;
        }

        //pour chaque langue du site courant, récupère les infos du site puis vérifie l'activation de l'epidemic map
        var promises = [];
        this.SITE.LANGUAGES.forEach((langue) => {
          promises.push(
            this._httpClientService.getTranslation(this.SITE_NAME, langue)
          );
        });
        Promise.all(promises).then((value) => {
          for (let i = 0; i < value.length; i++) {
            if (!this.isEpidMapActivated) {
              this.isEpidMapActivated = value[i]["map"]["activated"];
            }
          }
          let isMapEnabled =
            this.SITE.OPTIONS.MAP_ENABLED === "true" ? true : false;
          if (isMapEnabled && this.isEpidMapActivated) {
            this.landing_page_array.push({
              Key: "Epidemic Map",
              Value: `/home-map/${this.SITE_NAME.toLowerCase()}`,
            });
          }
          this.getTranslation(this.SITE.SITE, this.SITE.LANGUAGES);
        });
      })
      .catch((err) => this.callbackHTTPClient(err));

    this._httpClientService.getQRCodes(this.SITE_NAME).then((data) => {
      this.listQRCodes = data;
    });
  }

  ngOnInit(): void {
    this.initForm();
  }

  /**
   * Async function to consume service httpClientService and its function getTranslation
   * @param siteName Name of the site that we are consulting
   * @param language Language for which we want to get the translation
   */
  private async loadTranslation(siteName: string, language: string) {
    const translation = await this._httpService.getTranslation(
      siteName,
      language
    );
    return [language, translation];
  }

  /**
   * Get all translations for all languages in the order to fill the list of pages generated and activated
   * @param siteName Name of the site that we are consulting
   * @param languages Language(s) of the site
   */
  public getTranslation(siteName: string, languages: Array<string>) {
    Promise.all(languages.map((l) => this.loadTranslation(siteName, l))).then(
      (success) => {
        let pageIDAlreadyAdded: Array<string> = [];
        let landing_page_array = [];
        /** We loop on each languages to check if at least one page is activated.
         *  We add each page activated for each languages
         *  Before to add, we check if the pages is not already added to the array with pageIDAlreadyAdded batch array
         */
        for (let i = 0; i < success.length; i++) {
          success[i][1]["pages"].forEach((page, index) => {
            if (page.activated && !pageIDAlreadyAdded.includes(page.id)) {
              landing_page_array.push({
                Key: `${page.name}`,
                Value:
                  page.id === "home"
                    ? `/home/${this.SITE_NAME.toLowerCase()}`
                    : `/more-information/${this.SITE.SITE}/${page.id}`,
              });
              pageIDAlreadyAdded.push(page.id); // We use this batch array to check if the page is already in the array or not
            }
          });

          if (i === success.length - 1) {
            //if last loop we add all page to landing_pages_array
            this.landing_page_array = [
              ...landing_page_array,
              ...this.landing_page_array,
            ];
            console.log("landing page :", this.landing_page_array);
          }
        }
      }
    );
  }

  /** Function to init the form */
  initForm() {
    this.dynamicForm = this.formBuilder.group({
      qrcodes: new FormArray([]),
    });
  }

  activateCamera() {
    this.isCameraActivated = !this.isCameraActivated;
    this.scannerEnabled = !this.scannerEnabled;
    if (this.isCameraActivated && this.scannerEnabled) {
      console.log("Verifiez la camera  !");
      this.ALERT_MESSAGE.IS_DISPLAYED = true;
      this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "informativeModal";
      this.ALERT_MESSAGE.MESSAGE =
        "You must check that the camera is not obstructed !";
    }
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    async function getMedia(constraints) {
      let stream = null;
      try {
        stream = await navigator.mediaDevices.getUserMedia(constraints);
        /* use the stream */
        this.AutorizeMedia = true;
      } catch (err) {
        /* handle the error */
        this.AutorizeMedia = false;
      }
    }
  }

  onCodeResult(resultString: string) {
    this.qrResultString = resultString;
    this.qrResultUUID = this.qrResultString.split("/");
    this.IDtoSearch =
      this.qrResultUUID[Object.keys(this.qrResultUUID).length - 1];
    this.getOneQRCode();
    this.scannerEnabled = false;
    this.isCameraActivated = false;
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }

  onTorchCompatible(isCompatible: boolean): void {
    this.torchAvailable$.next(isCompatible || false);
  }

  toggleTorch(): void {
    this.torchEnabled = !this.torchEnabled;
  }

  toggleTryHarder(): void {
    this.tryHarder = !this.tryHarder;
  }

  loc1Andloc2() {
    return (form: FormGroup): { [key: string]: any } => {
      if (form.value.loc1 != "" && form.value.loc2 != "") {
        if (
          this.qrcode_init.loc1 != form.value.loc1 ||
          this.qrcode_init.loc2 != form.value.loc2
        ) {
          if (
            this.listQRCodes?.Items.find(
              (qrcode) =>
                qrcode.LOC1.S == form.value.loc1 &&
                qrcode.LOC2.S == form.value.loc2
            ) ||
            ""
          ) {
            return { loc1Andloc2DuplicateError: true };
          } else {
            return null;
          }
        } else {
          return null;
        }
      } else {
        return { loc1Andloc2RequiredError: true };
      }
    };
  }

  // convenience getters for easy access to form fields
  get f() {
    return this.dynamicForm.controls;
  }
  qrcodes(): FormArray {
    return this.dynamicForm.get("qrcodes") as FormArray;
  }

  /** Function to get one QR Code : from input sarch or scanner */
  getOneQRCode() {
    console.log(this.IDtoSearch);
    this._httpClientService
      .getQRCode(this.SITE_NAME, this.IDtoSearch)
      .then(async (data) => {
        this.search = data;
        console.log(this.search);
        if (this.search.Count === 0) {
          this.ALERT_MESSAGE.IS_DISPLAYED = true;
          this.ALERT_MESSAGE.MESSAGE = "Aucun résultat n'a été trouvé";
          this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "errorModal";
          return;
        }
        this.qrcode_init = {
          loc1: this.search.Items[0].LOC1.S,
          loc2: this.search.Items[0].LOC2.S,
        };
        const numberOfCodes = this.search.Count;

        if (this.qrcodes().length < numberOfCodes) {
          for (let i = this.qrcodes().length; i < numberOfCodes; i++) {
            console.log(this.search.Items[i]);

            this.data = `${environment.redirectUrl}/${this.SITE_NAME}/${this.search.Items[i].ID.S}`;

            if (!this.search.Items[i]["QR_CODES_CONFIG"]) {
              // apply default config if not extist
              this.search.Items[i]["QR_CODES_CONFIG"] =
                this._qrCodeGeneration.QR_CODES_CONFIG;
            }

            const qrConfig = this.search.Items[i]["QR_CODES_CONFIG"].M;
            this.QR_CODES_CONFIG =
              this._qrCodeGeneration.initGlobalConfig(qrConfig);

            // Verify if the qrcode has an image name
            if (qrConfig.imageName && qrConfig.imageName.S != "") {
              this.QR_CODES_CONFIG.IMAGE.IMAGE_NAME = qrConfig.imageName.S;
              if (qrConfig.imageName.S === "Logo_Elefight.png") {
                this.SRC_IMAGE_RENDER =
                  "../../../../../assets/img/Logo_Elefight.png";
              } else {
                this.SRC_IMAGE_RENDER = `${environment.inputPath}SITES/${this.SITE_NAME}/IMG/${qrConfig.imageName.S}`;
              }
            } else if (qrConfig.imageName && qrConfig.imageName.S == "") {
              this.QR_CODES_CONFIG.IMAGE.IMAGE_NAME = "";
              this.SRC_IMAGE_RENDER = "";
            }

            const newQRCode = this.formBuilder.group(
              {
                uuid: [this.search.Items[i].ID.S, Validators.required],
                site: [this.search.Items[i].SITE.S, [Validators.required]],
                loc1: [this.search.Items[i].LOC1.S, [Validators.required]],
                loc2: [this.search.Items[i].LOC2.S, [Validators.required]],
                landingPage: [
                  this.search.Items[i].LANDING_PAGE.S,
                  [Validators.required],
                ],
                externalUrl:
                  this.search.Items[i].LANDING_PAGE.S == "externalUrl"
                    ? [
                        this.search.Items[i].REDIRECT_URL.S,
                        [Validators.required],
                      ]
                    : [{ value: "", disabled: true }, [Validators.required]],
                creationDate: [
                  this.search.Items[i].CREATION_DATE
                    ? this.search.Items[i].CREATION_DATE.N
                    : "INVALID",
                ],
              },
              { validator: this.loc1Andloc2() }
            );

            this.qrcodes().push(newQRCode);
          }

          this.generated = true;
        } else {
          for (let i = this.qrcodes().length; i >= numberOfCodes; i--) {
            this.qrcodes().removeAt(i);
          }
        }
      })
      .catch((err) => this.callbackHTTPClient(err));
  }

  initQrCodeSettings() {
    if (this.qrCodeSettingsInitialized) {
      return;
    }

    // Créer une nouvelle instance de QRCodeStyling avec ou sans image
    const current_qr_code_preview = this._qrCodeGeneration.getQRCodeData(
      this.data,
      this.SRC_IMAGE_RENDER,
      300,
      this.QR_CODES_CONFIG
    );

    current_qr_code_preview.append(this.canvasConfig.nativeElement);

    this.qrCodeSettingsInitialized = true;
  }

  /** Timestamp Converter */
  timeConverter(epochtime) {
    if (epochtime != "INVALID") {
      var a = new Date(parseInt(epochtime));
      var months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var year = a.getFullYear();
      var month = months[a.getMonth()];
      var date = a.getDate();
      var hour = a.getHours().toString();
      if (hour.length === 1) {
        hour = "0" + hour;
      }
      var min = a.getMinutes().toString();
      if (min.length === 1) {
        min = "0" + min;
      }
      var time = date + " " + month + " " + year + " " + hour + ":" + min;
      return time;
    }
    return epochtime;
  }

  /** Remove a QR Code */
  removeQRCode(ITEM: any) {
    console.log("removeQRCode");
    this.ALERT_MESSAGE.IS_DISPLAYED = true;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "actionModal";
    this.ALERT_MESSAGE.TYPE_OF_ACTION = "remove";
    this.ALERT_MESSAGE.MESSAGE =
      "Are you sure you want to remove this QR Codes ?";

    this.ITEM = ITEM;
    console.log(this.ITEM);
  }

  callbackActionModal_remove() {
    console.log("callbackActionModal_remove");
    this._httpClientService
      .removeQRCode(this.SITE_NAME, this.ITEM.ID.S)
      .then((data) => {
        console.log(data);
        this.ALERT_MESSAGE.IS_DISPLAYED = true;
        this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "successModal";
        this.ALERT_MESSAGE.MESSAGE = "QR Code removed successfully";
      })
      .catch((err) => this.callbackHTTPClient(err));
    this.onReset();
    this.ALERT_MESSAGE.IS_DISPLAYED = false;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = null;
    this.ALERT_MESSAGE.TYPE_OF_ACTION = null;
    this.ALERT_MESSAGE.MESSAGE = null;
  }

  /** Print a QR Code */
  async printQRCode(ITEM: any) {
    console.log("PrintQRCode");
    const qrcode_to_download = this._qrCodeGeneration.getQRCodeData(
      this.data,
      this.SRC_IMAGE_RENDER,
      1000,
      this.QR_CODES_CONFIG
    );
    const imageData = await qrcode_to_download
      .getRawData("jpeg")
      .catch((error) => {
        console.error("Error generating QR code image:", error);
      });

    if (imageData) {
      const blob = new Blob([imageData], { type: "image/jpeg" });
      FileSaver.saveAs(
        blob,
        `${ITEM.SITE.S}_${ITEM.LOC1.S}_${ITEM.LOC2.S}.jpeg`
      );
    }
  }

  /** Save data */
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.dynamicForm.invalid) {
      return;
    }

    const input = this.dynamicForm.value.qrcodes;
    console.log("Input submit : ", JSON.parse(JSON.stringify(input)));
    for (let index = 0; index < input.length; index++) {
      if (
        input[index].landingPage == "externalUrl" &&
        !this._adminFormService.isEmail(input[index].externalUrl)
      ) {
        input[index].externalUrl = this._adminFormService.getValidUrl(
          input[index].externalUrl
        );
      } else if (
        input[index].landingPage == "externalUrl" &&
        this._adminFormService.isEmail(input[index].externalUrl)
      ) {
        input[index].externalUrl = this._adminFormService.getValidMailto(
          input[index].externalUrl
        );
      }
    }
    console.log(
      "Input submit after verification : ",
      JSON.parse(JSON.stringify(input))
    );

    this._httpClientService
      .postQRCode(this.SITE_NAME, input)
      .catch((err) => this.callbackHTTPClient(err));

    console.log("All QR Codes have been saved !");
    this.ALERT_MESSAGE.IS_DISPLAYED = true;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "successModal";
    this.ALERT_MESSAGE.MESSAGE = "All QR Codes have been saved !";
  }

  landingPageChanged(value: string, i: number) {
    if (value == "externalUrl") {
      this.qrcodes().at(i).get("externalUrl").enable();
    } else {
      this.qrcodes().at(i).get("externalUrl").disable();
    }
  }
  /** Reset whole form back to initial state */
  onReset() {
    this.submitted = false;
    this.generated = false;
    this.IDtoSearch = "";
    this.dynamicForm.reset();
    this.qrcodes().clear();
  }

  navigate(route: string) {
    this.router.navigate([`/admin/${this.SITE_NAME}/${route}`]);
  }

  /** Function to close the alert error when occured */
  closeAlertError(e) {
    this[e] = null;
  }

  /** Callback sent to {@link HTTPClientService} */
  callbackHTTPClient(message: string) {
    this.ALERT_MESSAGE.IS_DISPLAYED = true;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "errorModal";
    this.ALERT_MESSAGE.MESSAGE = "Erreur";
    this.message = "Erreur";
  }

  /** Callback to close modal and reset object ALERT_MESSAGE after displaying modal */
  callbackResetModal(ALERT_MESSAGE: ALERT_MESSAGE) {
    this.ALERT_MESSAGE.IS_DISPLAYED = false;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = null;
    this.ALERT_MESSAGE.MESSAGE = null;
  }

  ngOnDestroy() {
    this.scannerEnabled = false;
  }
}
