import { Injectable } from "@angular/core";
import Auth from "@aws-amplify/auth";
import { from, Observable } from "rxjs";
import { map } from "rxjs/operators";

export interface JwtClaims {
  [id: string]: any;
}

@Injectable({
  providedIn: "root",
})
export class UserLoginService {
  /**
   * Get ID Token of current user
   * @returns return ID Token of current user
   */
  public getTokenJwtClaims(): Observable<JwtClaims> {
    return from(Auth.currentSession()).pipe(
      map((session) => session.getIdToken().decodePayload())
    );
  }

  /**
   * Get current session
   * @returns return current session
   */
  async getSession() {
    return await Auth.currentSession();
  }

  /**
   * Refresh token
   * @returns
   */
  async refreshToken() {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    cognitoUser.refreshSession(
      currentSession.getRefreshToken(),
      (err, session) => {
        console.log("err :", err);
        console.log("session :", session);
        if (err?.name == "UserNotFoundException") {
          Auth.signOut();
          return;
        }
        const { idToken, refreshToken, accessToken } = session;
      }
    );
  }
}
