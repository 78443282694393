import { Injectable } from "@angular/core";
import QRCodeStyling, { DrawType } from "qr-code-styling";
import { QR_CODES_CONFIG } from "src/app/core/classes/QRCodesConfig";

@Injectable({
  providedIn: "root",
})
export class QRCodeGenerationService {
  /** Default QR Code Config */
  QR_CODES_CONFIG: QR_CODES_CONFIG = {
    COLOR: "#FFFFFF",
    SIZE: 300,
    MARGIN: 0,
    TYPE_NUMBER: 0,
    MODE: "Byte",
    ERROR_CORRECTION_LEVEL: "Q",
    COLOR_TYPE: 1,
    GRADIENT_OPTION: {
      TYPE: "linear",
      ROTATION: 0,
      COLOR_STOPS: [
        {
          OFFSET: 0,
          COLOR: "#FFFFFF",
        },
        {
          OFFSET: 1,
          COLOR: "#FFFFFF",
        },
      ],
    },
    IMAGE: {
      IMAGE_NAME: "Logo_Elefight.png",
      IS_BACK_DOTS_HIDDEN: true,
      IMAGE_SIZE: 0.3,
      MARGIN: 0,
    },
    DOTS: {
      STYLE: "rounded",
      COLOR_TYPE: 1,
      COLOR: "#000000",
      DOTS_GRADIENT_OPTION: {
        TYPE: "linear",
        ROTATION: 0,
        COLOR_STOPS: [
          {
            OFFSET: 0,
            COLOR: "#FFFFFF",
          },
          {
            OFFSET: 1,
            COLOR: "#FFFFFF",
          },
        ],
      },
    },
    CORNERS_SQUARE: {
      COLOR: "#000000",
      STYLE: "extra-rounded",
      COLOR_TYPE: 1,
      CORNERS_SQUARE_GRADIENT_OPTION: {
        TYPE: "linear",
        ROTATION: 0,
        COLOR_STOPS: [
          {
            OFFSET: 0,
            COLOR: "#FFFFFF",
          },
          {
            OFFSET: 1,
            COLOR: "#FFFFFF",
          },
        ],
      },
    },
    CORNERS_DOTS: {
      COLOR: "#000000",
      STYLE: "dot",
      COLOR_TYPE: 1,
      CORNERS_DOTS_GRADIENT_OPTION: {
        TYPE: "linear",
        ROTATION: 0,
        COLOR_STOPS: [
          {
            OFFSET: 0,
            COLOR: "#FFFFFF",
          },
          {
            OFFSET: 1,
            COLOR: "#FFFFFF",
          },
        ],
      },
    },
  };

  constructor() {}

  /**
   * Static function to generate QR Code
   * @param data Data that you want to put in the QR Code
   * @param image Image that you want to add into the QR Code
   * @param config Object that contains all configurations for QrCodeStyling library
   */
  getQRCodeData(data, image, size, config, type: DrawType = "canvas") {
    let qrcode_generated;

    if ("M" in config) {
      //if the config contains M, we have a DynamoDB format
      //adapt image margin with small qrcode format display
      let img_margin = parseInt(String(config.M.image.M.margin.N));

      if (img_margin > 0 && size != null && size < 300) {
        parseInt(String((img_margin /= 3 * (size / 100))));
      }

      //generate QR Code Styling with DynamoDB format
      qrcode_generated = new QRCodeStyling({
        width: size != null ? size : config.M.size.N,
        height: size != null ? size : config.M.size.N,
        data: data,
        type: type,
        margin: parseInt(String(config.M.margin.N)),
        qrOptions: {
          typeNumber: config.M.type_number.N,
          mode: config.M.mode.S,
          errorCorrectionLevel: config.M.error_correction_level.S,
        },
        image: image,
        imageOptions: {
          hideBackgroundDots: config.M.image.M.is_back_dots_hidden.BOOL,
          imageSize: config.M.image.M.image_size.N,
          margin: img_margin,
          crossOrigin: "anonymous",
        },
        dotsOptions: {
          type: config.M.dots.M.style.S,
          color: config.M.dots.M.color.S,
          gradient:
            config.M.dots.M.color_type.N === "2"
              ? {
                  type:
                    config.M.dots.M.dots_gradient_option.M.type.S !== null
                      ? config.M.dots.M.dots_gradient_option.M.type.S
                      : this.QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION.TYPE,
                  rotation:
                    config.M.dots.M.dots_gradient_option.M.rotation.N !== null
                      ? config.M.dots.M.dots_gradient_option.M.rotation.N
                      : this.QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION.ROTATION,
                  colorStops:
                    config.M.dots.M.dots_gradient_option.M.color_stops.L
                      .length > 0
                      ? [
                          {
                            offset:
                              config.M.dots.M.dots_gradient_option.M.color_stops
                                .L[0].M.offset.N != null
                                ? config.M.dots.M.dots_gradient_option.M
                                    .color_stops.L[0].M.offset.N
                                : this.QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION
                                    .COLOR_STOPS[0].OFFSET,
                            color:
                              config.M.dots.M.color.S != null
                                ? config.M.dots.M.color.S
                                : this.QR_CODES_CONFIG.DOTS.COLOR,
                          },
                          {
                            offset:
                              config.M.dots.M.dots_gradient_option.M.color_stops
                                .L[1].M.offset.N != null
                                ? config.M.dots.M.dots_gradient_option.M
                                    .color_stops.L[1].M.offset.N
                                : this.QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION
                                    .COLOR_STOPS[1].OFFSET,
                            color:
                              config.M.dots.M.dots_gradient_option.M.color_stops
                                .L[1].M.color.S != null
                                ? config.M.dots.M.dots_gradient_option.M
                                    .color_stops.L[1].M.color.S
                                : this.QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION
                                    .COLOR_STOPS[1].COLOR,
                          },
                        ]
                      : [
                          {
                            offset: parseInt(
                              String(
                                this.QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION
                                  .COLOR_STOPS[0].OFFSET
                              )
                            ),
                            color: this.QR_CODES_CONFIG.DOTS.COLOR,
                          },
                          {
                            offset: parseInt(
                              String(
                                this.QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION
                                  .COLOR_STOPS[1].OFFSET
                              )
                            ),
                            color:
                              this.QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION
                                .COLOR_STOPS[1].COLOR,
                          },
                        ],
                }
              : null,
        },
        cornersDotOptions: {
          type: config.M.corners_dots.M.style.S,
          color: config.M.corners_dots.M.color.S,
          gradient:
            config.M.corners_dots.M.color_type.N === "2"
              ? {
                  type:
                    config.M.corners_dots.M.corners_dots_gradient_option.M.type
                      .S !== null
                      ? config.M.corners_dots.M.corners_dots_gradient_option.M
                          .type.S
                      : this.QR_CODES_CONFIG.CORNERS_DOTS
                          .CORNERS_DOTS_GRADIENT_OPTION.TYPE,
                  rotation:
                    config.M.corners_dots.M.corners_dots_gradient_option.M
                      .rotation.N !== null
                      ? config.M.corners_dots.M.corners_dots_gradient_option.M
                          .rotation.N
                      : this.QR_CODES_CONFIG.CORNERS_DOTS
                          .CORNERS_DOTS_GRADIENT_OPTION.ROTATION,
                  colorStops:
                    config.M.corners_dots.M.corners_dots_gradient_option.M
                      .color_stops.L.length > 0
                      ? [
                          {
                            offset:
                              config.M.corners_dots.M
                                .corners_dots_gradient_option.M.color_stops.L[0]
                                .M.offset.N != null
                                ? config.M.corners_dots.M
                                    .corners_dots_gradient_option.M.color_stops
                                    .L[0].M.offset.N
                                : this.QR_CODES_CONFIG.CORNERS_DOTS
                                    .CORNERS_DOTS_GRADIENT_OPTION.COLOR_STOPS[0]
                                    .OFFSET,
                            color:
                              config.M.corners_dots.M.color.S != null
                                ? config.M.corners_dots.M.color.S
                                : this.QR_CODES_CONFIG.CORNERS_DOTS.COLOR,
                          },
                          {
                            offset:
                              config.M.corners_dots.M
                                .corners_dots_gradient_option.M.color_stops.L[1]
                                .M.offset.N != null
                                ? config.M.corners_dots.M
                                    .corners_dots_gradient_option.M.color_stops
                                    .L[1].M.offset.N
                                : this.QR_CODES_CONFIG.CORNERS_DOTS
                                    .CORNERS_DOTS_GRADIENT_OPTION.COLOR_STOPS[1]
                                    .OFFSET,
                            color:
                              config.M.corners_dots.M
                                .corners_dots_gradient_option.M.color_stops.L[1]
                                .M.color.S != null
                                ? config.M.corners_dots.M
                                    .corners_dots_gradient_option.M.color_stops
                                    .L[1].M.color.S
                                : this.QR_CODES_CONFIG.CORNERS_DOTS
                                    .CORNERS_DOTS_GRADIENT_OPTION.COLOR_STOPS[1]
                                    .COLOR,
                          },
                        ]
                      : [
                          {
                            offset: parseInt(
                              String(
                                this.QR_CODES_CONFIG.CORNERS_DOTS
                                  .CORNERS_DOTS_GRADIENT_OPTION.COLOR_STOPS[0]
                                  .OFFSET
                              )
                            ),
                            color: this.QR_CODES_CONFIG.CORNERS_DOTS.COLOR,
                          },
                          {
                            offset: parseInt(
                              String(
                                this.QR_CODES_CONFIG.CORNERS_DOTS
                                  .CORNERS_DOTS_GRADIENT_OPTION.COLOR_STOPS[1]
                                  .OFFSET
                              )
                            ),
                            color:
                              this.QR_CODES_CONFIG.CORNERS_DOTS
                                .CORNERS_DOTS_GRADIENT_OPTION.COLOR_STOPS[1]
                                .COLOR,
                          },
                        ],
                }
              : null,
        },
        cornersSquareOptions: {
          type: config.M.corners_square.M.style.S,
          color: config.M.corners_square.M.color.S,
          gradient:
            config.M.corners_square.M.color_type.N === "2"
              ? {
                  type:
                    config.M.corners_square.M.corners_square_gradient_option.M
                      .type.S !== null
                      ? config.M.corners_square.M.corners_square_gradient_option
                          .M.type.S
                      : this.QR_CODES_CONFIG.CORNERS_SQUARE
                          .CORNERS_SQUARE_GRADIENT_OPTION.TYPE,
                  rotation:
                    config.M.corners_square.M.corners_square_gradient_option.M
                      .rotation.N !== null
                      ? config.M.corners_square.M.corners_square_gradient_option
                          .M.rotation.N
                      : this.QR_CODES_CONFIG.CORNERS_SQUARE
                          .CORNERS_SQUARE_GRADIENT_OPTION.ROTATION,
                  colorStops:
                    config.M.corners_square.M.corners_square_gradient_option.M
                      .color_stops.L.length > 0
                      ? [
                          {
                            offset:
                              config.M.corners_square.M
                                .corners_square_gradient_option.M.color_stops
                                .L[0].M.offset.N != null
                                ? config.M.corners_square.M
                                    .corners_square_gradient_option.M
                                    .color_stops.L[0].M.offset.N
                                : this.QR_CODES_CONFIG.CORNERS_SQUARE
                                    .CORNERS_SQUARE_GRADIENT_OPTION
                                    .COLOR_STOPS[0].OFFSET,
                            color:
                              config.M.corners_square.M.color.S != null
                                ? config.M.corners_square.M.color.S
                                : this.QR_CODES_CONFIG.CORNERS_SQUARE.COLOR,
                          },
                          {
                            offset:
                              config.M.corners_square.M
                                .corners_square_gradient_option.M.color_stops
                                .L[1].M.offset.N != null
                                ? config.M.corners_square.M
                                    .corners_square_gradient_option.M
                                    .color_stops.L[1].M.offset.N
                                : this.QR_CODES_CONFIG.CORNERS_SQUARE
                                    .CORNERS_SQUARE_GRADIENT_OPTION
                                    .COLOR_STOPS[1].OFFSET,
                            color:
                              config.M.corners_square.M
                                .corners_square_gradient_option.M.color_stops
                                .L[1].M.color.S != null
                                ? config.M.corners_square.M
                                    .corners_square_gradient_option.M
                                    .color_stops.L[1].M.color.S
                                : this.QR_CODES_CONFIG.CORNERS_SQUARE
                                    .CORNERS_SQUARE_GRADIENT_OPTION
                                    .COLOR_STOPS[1].COLOR,
                          },
                        ]
                      : [
                          {
                            offset: parseInt(
                              String(
                                this.QR_CODES_CONFIG.CORNERS_SQUARE
                                  .CORNERS_SQUARE_GRADIENT_OPTION.COLOR_STOPS[0]
                                  .OFFSET
                              )
                            ),
                            color: this.QR_CODES_CONFIG.CORNERS_SQUARE.COLOR,
                          },
                          {
                            offset: parseInt(
                              String(
                                this.QR_CODES_CONFIG.CORNERS_SQUARE
                                  .CORNERS_SQUARE_GRADIENT_OPTION.COLOR_STOPS[1]
                                  .OFFSET
                              )
                            ),
                            color:
                              this.QR_CODES_CONFIG.CORNERS_SQUARE
                                .CORNERS_SQUARE_GRADIENT_OPTION.COLOR_STOPS[1]
                                .COLOR,
                          },
                        ],
                }
              : null,
        },
        backgroundOptions: {
          color: config.M.color.S,
          gradient:
            config.M.color_type.N === "2"
              ? {
                  type:
                    config.M.gradient_option.M.type.S !== null
                      ? config.M.gradient_option.M.type.S
                      : this.QR_CODES_CONFIG.GRADIENT_OPTION.TYPE,
                  rotation:
                    config.M.gradient_option.M.rotation.N !== null
                      ? config.M.gradient_option.M.rotation.N
                      : this.QR_CODES_CONFIG.GRADIENT_OPTION.ROTATION,
                  colorStops:
                    config.M.gradient_option.M.color_stops.L.length > 0
                      ? [
                          {
                            offset:
                              config.M.gradient_option.M.color_stops.L[0].M
                                .offset.N != null
                                ? config.M.gradient_option.M.color_stops.L[0].M
                                    .offset.N
                                : this.QR_CODES_CONFIG.GRADIENT_OPTION
                                    .COLOR_STOPS[0].OFFSET,
                            color:
                              config.M.color.S != null
                                ? config.M.color.S
                                : this.QR_CODES_CONFIG.COLOR,
                          },
                          {
                            offset:
                              config.M.gradient_option.M.color_stops.L[1].M
                                .offset.N != null
                                ? config.M.gradient_option.M.color_stops.L[1].M
                                    .offset.N
                                : this.QR_CODES_CONFIG.GRADIENT_OPTION
                                    .COLOR_STOPS[1].OFFSET,
                            color:
                              config.M.gradient_option.M.color_stops.L[1].M
                                .color.S != null
                                ? config.M.gradient_option.M.color_stops.L[1].M
                                    .color.S
                                : this.QR_CODES_CONFIG.GRADIENT_OPTION
                                    .COLOR_STOPS[1].COLOR,
                          },
                        ]
                      : [
                          {
                            offset: parseInt(
                              String(
                                this.QR_CODES_CONFIG.GRADIENT_OPTION
                                  .COLOR_STOPS[0].OFFSET
                              )
                            ),
                            color: this.QR_CODES_CONFIG.COLOR,
                          },
                          {
                            offset: parseInt(
                              String(
                                this.QR_CODES_CONFIG.GRADIENT_OPTION
                                  .COLOR_STOPS[1].OFFSET
                              )
                            ),
                            color:
                              this.QR_CODES_CONFIG.GRADIENT_OPTION
                                .COLOR_STOPS[1].COLOR,
                          },
                        ],
                }
              : null,
        },
      });
    } else {
      //else we have the basic format
      //generate QR Code with basic format
      qrcode_generated = new QRCodeStyling({
        width: size != null ? size : config.SIZE,
        height: size != null ? size : config.SIZE,
        data: data,
        image: image,
        margin: config.MARGIN,
        qrOptions: {
          typeNumber: config.TYPE_NUMBER,
          mode: config.MODE,
          errorCorrectionLevel: config.ERROR_CORRECTION_LEVEL,
        },
        imageOptions: {
          hideBackgroundDots: config.IMAGE.IS_BACK_DOTS_HIDDEN,
          imageSize: config.IMAGE.IMAGE_SIZE,
          margin: config.IMAGE.MARGIN,
          crossOrigin: "anonymous",
        },
        dotsOptions: {
          color: config.DOTS.COLOR,
          gradient:
            config.DOTS.COLOR_TYPE === 2
              ? {
                  type:
                    config.DOTS.DOTS_GRADIENT_OPTION.TYPE != null
                      ? config.DOTS.DOTS_GRADIENT_OPTION.TYPE
                      : this.QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION.TYPE,
                  rotation:
                    config.DOTS.DOTS_GRADIENT_OPTION.ROTATION != null
                      ? config.DOTS.DOTS_GRADIENT_OPTION.ROTATION
                      : this.QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION.ROTATION,
                  colorStops: [
                    {
                      offset:
                        config.DOTS.DOTS_GRADIENT_OPTION.COLOR_STOPS[0]
                          .OFFSET != null
                          ? config.DOTS.DOTS_GRADIENT_OPTION.COLOR_STOPS[0]
                              .OFFSET
                          : this.QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION
                              .COLOR_STOPS[0].OFFSET,
                      color:
                        config.DOTS.COLOR != null
                          ? config.DOTS.COLOR
                          : this.QR_CODES_CONFIG.DOTS.COLOR,
                    },
                    {
                      offset:
                        config.DOTS.DOTS_GRADIENT_OPTION.COLOR_STOPS[1]
                          .OFFSET != null
                          ? config.DOTS.DOTS_GRADIENT_OPTION.COLOR_STOPS[1]
                              .OFFSET
                          : this.QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION
                              .COLOR_STOPS[1].OFFSET,
                      color:
                        config.DOTS.DOTS_GRADIENT_OPTION.COLOR_STOPS[1].COLOR !=
                        null
                          ? config.DOTS.DOTS_GRADIENT_OPTION.COLOR_STOPS[1]
                              .COLOR
                          : this.QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION
                              .COLOR_STOPS[1].COLOR,
                    },
                  ],
                }
              : null,
          type: config.DOTS.STYLE,
        },
        backgroundOptions: {
          color: config.COLOR,
          gradient:
            config.COLOR_TYPE === 2
              ? {
                  type:
                    config.GRADIENT_OPTION.TYPE !== undefined
                      ? config.GRADIENT_OPTION.TYPE
                      : this.QR_CODES_CONFIG.GRADIENT_OPTION.TYPE,
                  rotation:
                    config.GRADIENT_OPTION.ROTATION !== undefined
                      ? config.GRADIENT_OPTION.ROTATION
                      : this.QR_CODES_CONFIG.GRADIENT_OPTION.ROTATION,
                  colorStops: [
                    {
                      offset:
                        config.GRADIENT_OPTION.COLOR_STOPS[0].OFFSET != null
                          ? config.GRADIENT_OPTION.COLOR_STOPS[0].OFFSET
                          : this.QR_CODES_CONFIG.GRADIENT_OPTION.COLOR_STOPS[0]
                              .OFFSET,
                      color:
                        config.COLOR != null
                          ? config.COLOR
                          : this.QR_CODES_CONFIG.COLOR,
                    },
                    {
                      offset:
                        config.GRADIENT_OPTION.COLOR_STOPS[1].OFFSET != null
                          ? config.GRADIENT_OPTION.COLOR_STOPS[1].OFFSET
                          : this.QR_CODES_CONFIG.GRADIENT_OPTION.COLOR_STOPS[1]
                              .OFFSET,
                      color:
                        config.GRADIENT_OPTION.COLOR_STOPS[1].COLOR != null
                          ? config.GRADIENT_OPTION.COLOR_STOPS[1].COLOR
                          : this.QR_CODES_CONFIG.GRADIENT_OPTION.COLOR_STOPS[1]
                              .COLOR,
                    },
                  ],
                }
              : null,
        },
        cornersSquareOptions: {
          color: config.CORNERS_SQUARE.COLOR,
          type: config.CORNERS_SQUARE.STYLE,
          gradient:
            config.CORNERS_SQUARE.COLOR_TYPE === 2
              ? {
                  type:
                    config.CORNERS_SQUARE.CORNERS_SQUARE_GRADIENT_OPTION
                      .TYPE !== undefined
                      ? config.CORNERS_SQUARE.CORNERS_SQUARE_GRADIENT_OPTION
                          .TYPE
                      : this.QR_CODES_CONFIG.CORNERS_SQUARE
                          .CORNERS_SQUARE_GRADIENT_OPTION.TYPE,
                  rotation:
                    config.CORNERS_SQUARE.CORNERS_SQUARE_GRADIENT_OPTION
                      .ROTATION !== undefined
                      ? config.CORNERS_SQUARE.CORNERS_SQUARE_GRADIENT_OPTION
                          .ROTATION
                      : this.QR_CODES_CONFIG.CORNERS_SQUARE
                          .CORNERS_SQUARE_GRADIENT_OPTION.ROTATION,
                  colorStops: [
                    {
                      offset:
                        config.CORNERS_SQUARE.CORNERS_SQUARE_GRADIENT_OPTION
                          .COLOR_STOPS[0].OFFSET != null
                          ? config.CORNERS_SQUARE.CORNERS_SQUARE_GRADIENT_OPTION
                              .COLOR_STOPS[0].OFFSET
                          : this.QR_CODES_CONFIG.CORNERS_SQUARE
                              .CORNERS_SQUARE_GRADIENT_OPTION.COLOR_STOPS[0]
                              .OFFSET,
                      color:
                        config.CORNERS_SQUARE.COLOR != null
                          ? config.CORNERS_SQUARE.COLOR
                          : this.QR_CODES_CONFIG.CORNERS_SQUARE.COLOR,
                    },
                    {
                      offset:
                        config.CORNERS_SQUARE.CORNERS_SQUARE_GRADIENT_OPTION
                          .COLOR_STOPS[1].OFFSET != null
                          ? config.CORNERS_SQUARE.CORNERS_SQUARE_GRADIENT_OPTION
                              .COLOR_STOPS[1].OFFSET
                          : this.QR_CODES_CONFIG.CORNERS_SQUARE
                              .CORNERS_SQUARE_GRADIENT_OPTION.COLOR_STOPS[1]
                              .OFFSET,
                      color:
                        config.CORNERS_SQUARE.CORNERS_SQUARE_GRADIENT_OPTION
                          .COLOR_STOPS[1].COLOR != null
                          ? config.CORNERS_SQUARE.CORNERS_SQUARE_GRADIENT_OPTION
                              .COLOR_STOPS[1].COLOR
                          : this.QR_CODES_CONFIG.CORNERS_SQUARE
                              .CORNERS_SQUARE_GRADIENT_OPTION.COLOR_STOPS[1]
                              .COLOR,
                    },
                  ],
                }
              : null,
        },
        cornersDotOptions: {
          color: config.CORNERS_DOTS.COLOR,
          type: config.CORNERS_DOTS.STYLE,
          gradient:
            config.CORNERS_DOTS.COLOR_TYPE === 2
              ? {
                  type:
                    config.CORNERS_DOTS.CORNERS_DOTS_GRADIENT_OPTION.TYPE !==
                    undefined
                      ? config.CORNERS_DOTS.CORNERS_DOTS_GRADIENT_OPTION.TYPE
                      : this.QR_CODES_CONFIG.CORNERS_DOTS
                          .CORNERS_DOTS_GRADIENT_OPTION.TYPE,
                  rotation:
                    config.CORNERS_DOTS.CORNERS_DOTS_GRADIENT_OPTION
                      .ROTATION !== undefined
                      ? config.CORNERS_DOTS.CORNERS_DOTS_GRADIENT_OPTION
                          .ROTATION
                      : this.QR_CODES_CONFIG.CORNERS_DOTS
                          .CORNERS_DOTS_GRADIENT_OPTION.ROTATION,
                  colorStops: [
                    {
                      offset:
                        config.CORNERS_DOTS.CORNERS_DOTS_GRADIENT_OPTION
                          .COLOR_STOPS[0].OFFSET != null
                          ? config.CORNERS_DOTS.CORNERS_DOTS_GRADIENT_OPTION
                              .COLOR_STOPS[0].OFFSET
                          : this.QR_CODES_CONFIG.CORNERS_DOTS
                              .CORNERS_DOTS_GRADIENT_OPTION.COLOR_STOPS[0]
                              .OFFSET,
                      color:
                        config.CORNERS_DOTS.COLOR != null
                          ? config.CORNERS_DOTS.COLOR
                          : this.QR_CODES_CONFIG.CORNERS_DOTS.COLOR,
                    },
                    {
                      offset:
                        config.CORNERS_DOTS.CORNERS_DOTS_GRADIENT_OPTION
                          .COLOR_STOPS[1].OFFSET != null
                          ? config.CORNERS_DOTS.CORNERS_DOTS_GRADIENT_OPTION
                              .COLOR_STOPS[1].OFFSET
                          : this.QR_CODES_CONFIG.CORNERS_DOTS
                              .CORNERS_DOTS_GRADIENT_OPTION.COLOR_STOPS[1]
                              .OFFSET,
                      color:
                        config.CORNERS_DOTS.CORNERS_DOTS_GRADIENT_OPTION
                          .COLOR_STOPS[1].COLOR != null
                          ? config.CORNERS_DOTS.CORNERS_DOTS_GRADIENT_OPTION
                              .COLOR_STOPS[1].COLOR
                          : this.QR_CODES_CONFIG.CORNERS_DOTS
                              .CORNERS_DOTS_GRADIENT_OPTION.COLOR_STOPS[1]
                              .COLOR,
                    },
                  ],
                }
              : null,
        },
      });
    }

    return qrcode_generated;
  }

  /** Get the default QR Code config with DynamoDB format */
  getQrCodeDefaultConfig() {
    return {
      M: {
        color: {
          S: this.QR_CODES_CONFIG.COLOR,
        },
        color_type: {
          N: this.QR_CODES_CONFIG.COLOR_TYPE,
        },
        size: {
          N: this.QR_CODES_CONFIG.SIZE,
        },
        image: {
          M: {
            image_name: {
              S: this.QR_CODES_CONFIG.IMAGE.IMAGE_NAME,
            },
            image_size: {
              N: this.QR_CODES_CONFIG.IMAGE.IMAGE_SIZE,
            },
            is_back_dots_hidden: {
              BOOL: this.QR_CODES_CONFIG.IMAGE.IS_BACK_DOTS_HIDDEN,
            },
            margin: {
              N: this.QR_CODES_CONFIG.IMAGE.MARGIN,
            },
            crossOrigin: {
              S: "anonymous",
            },
          },
        },
        margin: {
          N: this.QR_CODES_CONFIG.MARGIN,
        },
        type_number: {
          N: this.QR_CODES_CONFIG.TYPE_NUMBER,
        },
        mode: {
          S: this.QR_CODES_CONFIG.MODE,
        },
        error_correction_level: {
          S: this.QR_CODES_CONFIG.ERROR_CORRECTION_LEVEL,
        },
        dots: {
          M: {
            style: {
              S: this.QR_CODES_CONFIG.DOTS.STYLE,
            },
            color: {
              S: this.QR_CODES_CONFIG.DOTS.COLOR,
            },
            color_type: {
              N: this.QR_CODES_CONFIG.DOTS.COLOR_TYPE,
            },
            dots_gradient_option: {
              M: {
                type: {
                  S: this.QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION.TYPE,
                },
                rotation: {
                  N: this.QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION.ROTATION.toString(),
                },
                color_stops: {
                  L: [
                    {
                      M: {
                        offset: {
                          N: this.QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION.COLOR_STOPS[0].OFFSET.toString(),
                        },
                        color: {
                          S: this.QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION
                            .COLOR_STOPS[0].COLOR,
                        },
                      },
                    },
                    {
                      M: {
                        offset: {
                          N: this.QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION.COLOR_STOPS[1].OFFSET.toString(),
                        },
                        color: {
                          S: this.QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION
                            .COLOR_STOPS[1].COLOR,
                        },
                      },
                    },
                  ],
                },
              },
            },
          },
        },
        corners_dots: {
          M: {
            style: {
              S: this.QR_CODES_CONFIG.CORNERS_DOTS.STYLE,
            },
            color: {
              S: this.QR_CODES_CONFIG.CORNERS_DOTS.COLOR,
            },
            color_type: {
              N: this.QR_CODES_CONFIG.CORNERS_DOTS.COLOR_TYPE,
            },
            corners_dots_gradient_option: {
              M: {
                type: {
                  S: this.QR_CODES_CONFIG.CORNERS_DOTS
                    .CORNERS_DOTS_GRADIENT_OPTION.TYPE,
                },
                rotation: {
                  N: this.QR_CODES_CONFIG.CORNERS_DOTS.CORNERS_DOTS_GRADIENT_OPTION.ROTATION.toString(),
                },
                color_stops: {
                  L: [
                    {
                      M: {
                        offset: {
                          N: this.QR_CODES_CONFIG.CORNERS_DOTS.CORNERS_DOTS_GRADIENT_OPTION.COLOR_STOPS[0].OFFSET.toString(),
                        },
                        color: {
                          S: this.QR_CODES_CONFIG.CORNERS_DOTS
                            .CORNERS_DOTS_GRADIENT_OPTION.COLOR_STOPS[0].COLOR,
                        },
                      },
                    },
                    {
                      M: {
                        offset: {
                          N: this.QR_CODES_CONFIG.CORNERS_DOTS.CORNERS_DOTS_GRADIENT_OPTION.COLOR_STOPS[1].OFFSET.toString(),
                        },
                        color: {
                          S: this.QR_CODES_CONFIG.CORNERS_DOTS
                            .CORNERS_DOTS_GRADIENT_OPTION.COLOR_STOPS[1].COLOR,
                        },
                      },
                    },
                  ],
                },
              },
            },
          },
        },
        corners_square: {
          M: {
            style: {
              S: this.QR_CODES_CONFIG.CORNERS_SQUARE.STYLE,
            },
            color: {
              S: this.QR_CODES_CONFIG.CORNERS_SQUARE.COLOR,
            },
            color_type: {
              N: this.QR_CODES_CONFIG.CORNERS_SQUARE.COLOR_TYPE,
            },
            corners_square_gradient_option: {
              M: {
                type: {
                  S: this.QR_CODES_CONFIG.CORNERS_SQUARE
                    .CORNERS_SQUARE_GRADIENT_OPTION.TYPE,
                },
                rotation: {
                  N: this.QR_CODES_CONFIG.CORNERS_SQUARE.CORNERS_SQUARE_GRADIENT_OPTION.ROTATION.toString(),
                },
                color_stops: {
                  L: [
                    {
                      M: {
                        offset: {
                          N: this.QR_CODES_CONFIG.CORNERS_SQUARE.CORNERS_SQUARE_GRADIENT_OPTION.COLOR_STOPS[0].OFFSET.toString(),
                        },
                        color: {
                          S: this.QR_CODES_CONFIG.CORNERS_SQUARE
                            .CORNERS_SQUARE_GRADIENT_OPTION.COLOR_STOPS[0]
                            .COLOR,
                        },
                      },
                    },
                    {
                      M: {
                        offset: {
                          N: this.QR_CODES_CONFIG.CORNERS_SQUARE.CORNERS_SQUARE_GRADIENT_OPTION.COLOR_STOPS[1].OFFSET.toString(),
                        },
                        color: {
                          S: this.QR_CODES_CONFIG.CORNERS_SQUARE
                            .CORNERS_SQUARE_GRADIENT_OPTION.COLOR_STOPS[1]
                            .COLOR,
                        },
                      },
                    },
                  ],
                },
              },
            },
          },
        },
        gradient_option: {
          M: {
            type: {
              S: this.QR_CODES_CONFIG.GRADIENT_OPTION.TYPE,
            },
            rotation: {
              N: this.QR_CODES_CONFIG.GRADIENT_OPTION.ROTATION.toString(),
            },
            color_stops: {
              L: [
                {
                  M: {
                    offset: {
                      N: this.QR_CODES_CONFIG.GRADIENT_OPTION.COLOR_STOPS[0].OFFSET.toString(),
                    },
                    color: {
                      S: this.QR_CODES_CONFIG.GRADIENT_OPTION.COLOR_STOPS[0]
                        .COLOR,
                    },
                  },
                },
                {
                  M: {
                    offset: {
                      N: this.QR_CODES_CONFIG.GRADIENT_OPTION.COLOR_STOPS[1].OFFSET.toString(),
                    },
                    color: {
                      S: this.QR_CODES_CONFIG.GRADIENT_OPTION.COLOR_STOPS[1]
                        .COLOR,
                    },
                  },
                },
              ],
            },
          },
        },
      },
    };
  }

  initGlobalConfig(qrConfig) {
    let QR_CODES_CONFIG_TEMP = this.QR_CODES_CONFIG;
    return (QR_CODES_CONFIG_TEMP = {
      COLOR: qrConfig.M.color ? qrConfig.M.color.S : this.QR_CODES_CONFIG.COLOR,
      SIZE: this.QR_CODES_CONFIG.SIZE,
      MARGIN: parseInt(
        String(
          qrConfig.M.margin ? qrConfig.M.margin.N : this.QR_CODES_CONFIG.MARGIN
        )
      ),
      TYPE_NUMBER: qrConfig.M.type_number
        ? qrConfig.M.type_number.N
        : this.QR_CODES_CONFIG.TYPE_NUMBER,
      MODE: qrConfig.M.mode ? qrConfig.M.mode.S : this.QR_CODES_CONFIG.MODE,
      ERROR_CORRECTION_LEVEL: qrConfig.M.error_correction_level
        ? qrConfig.M.error_correction_level.S
        : this.QR_CODES_CONFIG.ERROR_CORRECTION_LEVEL,
      COLOR_TYPE: parseInt(
        String(
          qrConfig.M.color_type
            ? qrConfig.M.color_type.N
            : this.QR_CODES_CONFIG.COLOR_TYPE
        )
      ),
      GRADIENT_OPTION: {
        TYPE:
          qrConfig.M.gradient_option.M.type.S != null
            ? qrConfig.M.gradient_option.M.type.S
            : this.QR_CODES_CONFIG.GRADIENT_OPTION.TYPE,
        ROTATION: parseInt(
          String(
            qrConfig.M.gradient_option.M.rotation.N != null
              ? qrConfig.M.gradient_option.M.rotation.N
              : this.QR_CODES_CONFIG.GRADIENT_OPTION.ROTATION
          )
        ),
        COLOR_STOPS:
          qrConfig.M.gradient_option.M.color_stops.L.length > 0
            ? [
                {
                  OFFSET: parseInt(
                    String(
                      qrConfig.M.gradient_option.M.color_stops.L[0].M.offset
                        .N != null
                        ? qrConfig.M.gradient_option.M.color_stops.L[0].M.offset
                            .N
                        : this.QR_CODES_CONFIG.GRADIENT_OPTION.COLOR_STOPS[0]
                            .OFFSET
                    )
                  ),
                  COLOR:
                    qrConfig.M.color.S != null
                      ? qrConfig.M.color.S
                      : this.QR_CODES_CONFIG.COLOR,
                },
                {
                  OFFSET: parseInt(
                    String(
                      qrConfig.M.gradient_option.M.color_stops.L[1].M.offset
                        .N != null
                        ? qrConfig.M.gradient_option.M.color_stops.L[1].M.offset
                            .N
                        : this.QR_CODES_CONFIG.GRADIENT_OPTION.COLOR_STOPS[1]
                            .OFFSET
                    )
                  ),
                  COLOR:
                    qrConfig.M.gradient_option.M.color_stops.L[1].M.color.S !=
                    null
                      ? qrConfig.M.gradient_option.M.color_stops.L[1].M.color.S
                      : this.QR_CODES_CONFIG.GRADIENT_OPTION.COLOR_STOPS[1]
                          .COLOR,
                },
              ]
            : [
                {
                  OFFSET: parseInt(
                    String(
                      this.QR_CODES_CONFIG.GRADIENT_OPTION.COLOR_STOPS[0].OFFSET
                    )
                  ),
                  COLOR: this.QR_CODES_CONFIG.COLOR,
                },
                {
                  OFFSET: parseInt(
                    String(
                      this.QR_CODES_CONFIG.GRADIENT_OPTION.COLOR_STOPS[1].OFFSET
                    )
                  ),
                  COLOR:
                    this.QR_CODES_CONFIG.GRADIENT_OPTION.COLOR_STOPS[1].COLOR,
                },
              ],
      },
      IMAGE: {
        IMAGE_NAME: qrConfig.M.image.M.image_name
          ? qrConfig.M.image.M.image_name.S
          : this.QR_CODES_CONFIG.IMAGE.IMAGE_NAME,
        IS_BACK_DOTS_HIDDEN: qrConfig.M.image.M.is_back_dots_hidden
          ? qrConfig.M.image.M.is_back_dots_hidden.BOOL
          : this.QR_CODES_CONFIG.IMAGE.IS_BACK_DOTS_HIDDEN,
        IMAGE_SIZE: parseFloat(
          String(
            qrConfig.M.image.M.image_size
              ? qrConfig.M.image.M.image_size.N
              : this.QR_CODES_CONFIG.IMAGE.IMAGE_SIZE
          )
        ),
        MARGIN: parseInt(
          String(
            qrConfig.M.image.M.margin
              ? qrConfig.M.image.M.margin.N
              : this.QR_CODES_CONFIG.IMAGE.MARGIN
          )
        ),
      },
      DOTS: {
        STYLE: qrConfig.M.dots.M.style
          ? qrConfig.M.dots.M.style.S
          : this.QR_CODES_CONFIG.DOTS.STYLE,
        COLOR_TYPE: parseInt(
          String(
            qrConfig.M.dots.M.color_type
              ? qrConfig.M.dots.M.color_type.N
              : this.QR_CODES_CONFIG.DOTS.COLOR_TYPE
          )
        ),
        COLOR: qrConfig.M.dots.M.color
          ? qrConfig.M.dots.M.color.S
          : this.QR_CODES_CONFIG.DOTS.COLOR,
        DOTS_GRADIENT_OPTION: {
          TYPE:
            qrConfig.M.dots.M.dots_gradient_option.M.type.S != null
              ? qrConfig.M.dots.M.dots_gradient_option.M.type.S
              : this.QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION.TYPE,
          ROTATION: parseInt(
            String(
              qrConfig.M.dots.M.dots_gradient_option.M.rotation.N != null
                ? qrConfig.M.dots.M.dots_gradient_option.M.rotation.N
                : this.QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION.ROTATION
            )
          ),
          COLOR_STOPS:
            qrConfig.M.dots.M.dots_gradient_option.M.color_stops.L.length > 0
              ? [
                  {
                    OFFSET: parseInt(
                      String(
                        qrConfig.M.dots.M.dots_gradient_option.M.color_stops
                          .L[0].M.offset.N != null
                          ? qrConfig.M.dots.M.dots_gradient_option.M.color_stops
                              .L[0].M.offset.N
                          : this.QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION
                              .COLOR_STOPS[0].OFFSET
                      )
                    ),
                    COLOR:
                      qrConfig.M.dots.M.color.S != null
                        ? qrConfig.M.dots.M.color.S
                        : this.QR_CODES_CONFIG.DOTS.COLOR,
                  },
                  {
                    OFFSET: parseInt(
                      String(
                        qrConfig.M.dots.M.dots_gradient_option.M.color_stops
                          .L[1].M.offset.N != null
                          ? qrConfig.M.dots.M.dots_gradient_option.M.color_stops
                              .L[1].M.offset.N
                          : this.QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION
                              .COLOR_STOPS[1].OFFSET
                      )
                    ),
                    COLOR:
                      qrConfig.M.dots.M.dots_gradient_option.M.color_stops.L[1]
                        .M.color.S != null
                        ? qrConfig.M.dots.M.dots_gradient_option.M.color_stops
                            .L[1].M.color.S
                        : this.QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION
                            .COLOR_STOPS[1].COLOR,
                  },
                ]
              : [
                  {
                    OFFSET: parseInt(
                      String(
                        this.QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION
                          .COLOR_STOPS[0].OFFSET
                      )
                    ),
                    COLOR: this.QR_CODES_CONFIG.DOTS.COLOR,
                  },
                  {
                    OFFSET: parseInt(
                      String(
                        this.QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION
                          .COLOR_STOPS[1].OFFSET
                      )
                    ),
                    COLOR:
                      this.QR_CODES_CONFIG.DOTS.DOTS_GRADIENT_OPTION
                        .COLOR_STOPS[1].COLOR,
                  },
                ],
        },
      },
      CORNERS_SQUARE: {
        STYLE: qrConfig.M.corners_square.M.style
          ? qrConfig.M.corners_square.M.style.S
          : this.QR_CODES_CONFIG.CORNERS_SQUARE.STYLE,
        COLOR_TYPE: parseInt(
          String(
            qrConfig.M.corners_square.M.color_type
              ? qrConfig.M.corners_square.M.color_type.N
              : this.QR_CODES_CONFIG.CORNERS_SQUARE.COLOR_TYPE
          )
        ),
        COLOR: qrConfig.M.corners_square.M.color
          ? qrConfig.M.corners_square.M.color.S
          : this.QR_CODES_CONFIG.CORNERS_SQUARE.COLOR,
        CORNERS_SQUARE_GRADIENT_OPTION: {
          TYPE:
            qrConfig.M.corners_square.M.corners_square_gradient_option.M.type
              .S != null
              ? qrConfig.M.corners_square.M.corners_square_gradient_option.M
                  .type.S
              : this.QR_CODES_CONFIG.CORNERS_SQUARE
                  .CORNERS_SQUARE_GRADIENT_OPTION.TYPE,
          ROTATION: parseInt(
            String(
              qrConfig.M.corners_square.M.corners_square_gradient_option.M
                .rotation.N != null
                ? qrConfig.M.corners_square.M.corners_square_gradient_option.M
                    .rotation.N
                : this.QR_CODES_CONFIG.CORNERS_SQUARE
                    .CORNERS_SQUARE_GRADIENT_OPTION.ROTATION
            )
          ),
          COLOR_STOPS:
            qrConfig.M.corners_square.M.corners_square_gradient_option.M
              .color_stops.L.length > 0
              ? [
                  {
                    OFFSET: parseInt(
                      String(
                        qrConfig.M.corners_square.M
                          .corners_square_gradient_option.M.color_stops.L[0].M
                          .offset.N != null
                          ? qrConfig.M.corners_square.M
                              .corners_square_gradient_option.M.color_stops.L[0]
                              .M.offset.N
                          : this.QR_CODES_CONFIG.CORNERS_SQUARE
                              .CORNERS_SQUARE_GRADIENT_OPTION.COLOR_STOPS[0]
                              .OFFSET
                      )
                    ),
                    COLOR: qrConfig.M.corners_square.M.color
                      ? qrConfig.M.corners_square.M.color.S
                      : this.QR_CODES_CONFIG.CORNERS_SQUARE.COLOR,
                  },
                  {
                    OFFSET: parseInt(
                      String(
                        qrConfig.M.corners_square.M
                          .corners_square_gradient_option.M.color_stops.L[1].M
                          .offset.N != null
                          ? qrConfig.M.corners_square.M
                              .corners_square_gradient_option.M.color_stops.L[1]
                              .M.offset.N
                          : this.QR_CODES_CONFIG.CORNERS_SQUARE
                              .CORNERS_SQUARE_GRADIENT_OPTION.COLOR_STOPS[1]
                              .OFFSET
                      )
                    ),
                    COLOR: qrConfig.M.corners_square.M
                      .corners_square_gradient_option.M.color_stops.L[1].M.color
                      ? qrConfig.M.corners_square.M
                          .corners_square_gradient_option.M.color_stops.L[1].M
                          .color.S
                      : this.QR_CODES_CONFIG.CORNERS_SQUARE
                          .CORNERS_SQUARE_GRADIENT_OPTION.COLOR_STOPS[1].COLOR,
                  },
                ]
              : [
                  {
                    OFFSET: parseInt(
                      String(
                        this.QR_CODES_CONFIG.CORNERS_SQUARE
                          .CORNERS_SQUARE_GRADIENT_OPTION.COLOR_STOPS[0].OFFSET
                      )
                    ),
                    COLOR: this.QR_CODES_CONFIG.CORNERS_SQUARE.COLOR,
                  },
                  {
                    OFFSET: parseInt(
                      String(
                        this.QR_CODES_CONFIG.CORNERS_SQUARE
                          .CORNERS_SQUARE_GRADIENT_OPTION.COLOR_STOPS[1].OFFSET
                      )
                    ),
                    COLOR:
                      this.QR_CODES_CONFIG.CORNERS_SQUARE
                        .CORNERS_SQUARE_GRADIENT_OPTION.COLOR_STOPS[1].COLOR,
                  },
                ],
        },
      },
      CORNERS_DOTS: {
        STYLE: qrConfig.M.corners_dots.M.style
          ? qrConfig.M.corners_dots.M.style.S
          : this.QR_CODES_CONFIG.CORNERS_DOTS.STYLE,
        COLOR_TYPE: parseInt(
          String(
            qrConfig.M.corners_dots.M.color_type
              ? qrConfig.M.corners_dots.M.color_type.N
              : this.QR_CODES_CONFIG.CORNERS_DOTS.COLOR_TYPE
          )
        ),
        COLOR: qrConfig.M.corners_dots.M.color
          ? qrConfig.M.corners_dots.M.color.S
          : this.QR_CODES_CONFIG.CORNERS_DOTS.COLOR,
        CORNERS_DOTS_GRADIENT_OPTION: {
          TYPE:
            qrConfig.M.corners_dots.M.corners_dots_gradient_option.M.type.S !=
            null
              ? qrConfig.M.corners_dots.M.corners_dots_gradient_option.M.type.S
              : this.QR_CODES_CONFIG.CORNERS_DOTS.CORNERS_DOTS_GRADIENT_OPTION
                  .TYPE,
          ROTATION: parseInt(
            String(
              qrConfig.M.corners_dots.M.corners_dots_gradient_option.M.rotation
                .N != null
                ? qrConfig.M.corners_dots.M.corners_dots_gradient_option.M
                    .rotation.N
                : this.QR_CODES_CONFIG.CORNERS_DOTS.CORNERS_DOTS_GRADIENT_OPTION
                    .ROTATION
            )
          ),
          COLOR_STOPS:
            qrConfig.M.corners_dots.M.corners_dots_gradient_option.M.color_stops
              .L.length > 0
              ? [
                  {
                    OFFSET: parseInt(
                      String(
                        qrConfig.M.corners_dots.M.corners_dots_gradient_option.M
                          .color_stops.L[0].M.offset.N != null
                          ? qrConfig.M.corners_dots.M
                              .corners_dots_gradient_option.M.color_stops.L[0].M
                              .offset.N
                          : this.QR_CODES_CONFIG.CORNERS_DOTS
                              .CORNERS_DOTS_GRADIENT_OPTION.COLOR_STOPS[0]
                              .OFFSET
                      )
                    ),
                    COLOR: qrConfig.M.corners_dots.M.color
                      ? qrConfig.M.corners_dots.M.color.S
                      : this.QR_CODES_CONFIG.CORNERS_DOTS.COLOR,
                  },
                  {
                    OFFSET: parseInt(
                      String(
                        qrConfig.M.corners_dots.M.corners_dots_gradient_option.M
                          .color_stops.L[1].M.offset.N != null
                          ? qrConfig.M.corners_dots.M
                              .corners_dots_gradient_option.M.color_stops.L[1].M
                              .offset.N
                          : this.QR_CODES_CONFIG.CORNERS_DOTS
                              .CORNERS_DOTS_GRADIENT_OPTION.COLOR_STOPS[1]
                              .OFFSET
                      )
                    ),
                    COLOR: qrConfig.M.corners_dots.M
                      .corners_dots_gradient_option.M.color_stops.L[1].M.color
                      ? qrConfig.M.corners_dots.M.corners_dots_gradient_option.M
                          .color_stops.L[1].M.color.S
                      : this.QR_CODES_CONFIG.CORNERS_DOTS
                          .CORNERS_DOTS_GRADIENT_OPTION.COLOR_STOPS[1].COLOR,
                  },
                ]
              : [
                  {
                    OFFSET: parseInt(
                      String(
                        this.QR_CODES_CONFIG.CORNERS_DOTS
                          .CORNERS_DOTS_GRADIENT_OPTION.COLOR_STOPS[0].OFFSET
                      )
                    ),
                    COLOR: this.QR_CODES_CONFIG.CORNERS_DOTS.COLOR,
                  },
                  {
                    OFFSET: parseInt(
                      String(
                        this.QR_CODES_CONFIG.CORNERS_DOTS
                          .CORNERS_DOTS_GRADIENT_OPTION.COLOR_STOPS[1].OFFSET
                      )
                    ),
                    COLOR:
                      this.QR_CODES_CONFIG.CORNERS_DOTS
                        .CORNERS_DOTS_GRADIENT_OPTION.COLOR_STOPS[1].COLOR,
                  },
                ],
        },
      },
    });
  }
}
