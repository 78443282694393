import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

/** Service with function to share data between components. */
@Injectable({
  providedIn: "root",
})
export class ShareDataService {
  private ZIPCodes = new BehaviorSubject(null);
  currentZIPCodes = this.ZIPCodes.asObservable();

  private weekNumber = new BehaviorSubject(0);
  currentWeekNumber = this.weekNumber.asObservable();

  private subRegionNumber = new BehaviorSubject("");
  currentSubRegionNumber = this.subRegionNumber.asObservable();

  private dataDashboard = new BehaviorSubject(null);
  currentDataDashboard = this.dataDashboard.asObservable();

  constructor() {}

  /**
   * Function to change the value of ZIP of QR Codes
   * @param ZIP Value of the ZIP
   */
  changeZIPCodes(ZIP) {
    this.ZIPCodes.next(ZIP);
    console.log("ZIPCodes has changed");
  }

  /**
   * Function to change the value of the selected week in dashboard
   * @param WeekNumber value of the week number
   */
  changeWeekNumber(WeekNumber: number) {
    this.weekNumber.next(WeekNumber);
    console.log("WeekNumber has changed");
  }

  /**
   * Function to change value of region number in dashboard
   * @param SubRegionNumber value of the region number
   */
  changeSubRegionNumber(SubRegionNumber: string) {
    this.subRegionNumber.next(SubRegionNumber);
    console.log("SubRegionNumber has changed");
  }

  /**
   * Function to change data of the dashboard
   * @param Data value of the data
   */
  changeDataDashboard(Data: any) {
    this.dataDashboard.next(Data);
    console.log("dataDashboard has changed");
  }
}
