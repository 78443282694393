import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import Auth from "@aws-amplify/auth";

import { from, Observable } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";

/**
 * This will append jwt token for the http requests.
 *
 * @export
 * @class JwtInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(Auth.currentSession()).pipe(
      switchMap((auth: any) => {
        // switchMap() is used instead of map().

        let jwt = auth.idToken.jwtToken;
        let authentifiedRequest = request.clone({
          setHeaders: {
            Authorization: `Bearer ${jwt}`,
          },
        });
        console.debug("Cognito JWT token added to HTTP request header");
        return next.handle(authentifiedRequest);
      }),
      catchError((err: any) => {
        console.error("Cognito session error :", err);
        return next.handle(request);
      })
    );
  }
}
