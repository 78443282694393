import { NgModule, Optional, SkipSelf } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { SharedModule } from "../shared/shared.module";
import { PublicModule } from "../public/public.module";
import { PrivateModule } from "../private/private.module";
import { TranslateModule } from "@ngx-translate/core";
import { BeginsWithPipe } from "../shared/pipes/begins-with.pipe";
import { ShortUrlPipe } from "../shared/pipes/short-url.pipe";
import { SafeUrlPipe } from "../shared/pipes/safe-url.pipe";

@NgModule({
  declarations: [],
  imports: [CommonModule, PublicModule, PrivateModule, SharedModule],
  providers: [
    TranslateModule,
    BeginsWithPipe,
    ShortUrlPipe,
    DatePipe,
    SafeUrlPipe,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error("CoreModule is already loaded.");
    }
  }
}
