import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class SitesService {
  /** URL de l'API */
  apiURL = environment.apiUrl;
  API_path = "sites";

  constructor(private httpClient: HttpClient) {}

  /**
   * Get list of sites
   * @returns return a promise with JSON
   */
  getSites() {
    console.debug(`GET ${this.apiURL}/${this.API_path}`);
    return this.httpClient.get(`${this.apiURL}/${this.API_path}`).toPromise();
  }

  /**
   * Get informaiton about one site
   * @param site Name of the site
   * @returns return a promise with JSON
   */
  getSite(site: string) {
    console.debug(`GET ${this.apiURL}/${this.API_path}/${site.toUpperCase()}`);
    return this.httpClient
      .get(`${this.apiURL}/${this.API_path}/${site.toUpperCase()}`)
      .toPromise();
  }

  /**
   * Create a new site
   * @param body body with content of the new site
   * @returns return a promise with achievement or not of the API call
   */
  postSite(body) {
    console.debug(`POST ${this.apiURL}/${this.API_path}`);
    return this.httpClient
      .post(`${this.apiURL}/${this.API_path}`, body)
      .toPromise();
  }

  /**
   * Modify an existing site
   * @param body body with content of the site
   * @returns return a promise with achievement or not of the API call
   */
  modifySite(body) {
    console.debug(`PUT ${this.apiURL}/${this.API_path}/${body.site}`);
    return this.httpClient
      .put(`${this.apiURL}/${this.API_path}/${body.site}`, body)
      .toPromise();
  }

  /**
   * Remove an existing site
   * @param site Name of the site
   * @returns return a promise with achievement or not of the API call
   */
  deleteSite(site) {
    console.debug(`POST ${this.apiURL}/${this.API_path}/${site}/remove`);
    // return this.httpClient.delete(`${this.apiURL}/${this.API_path}/${site}`).toPromise();
    return this.httpClient
      .post(`${this.apiURL}/${this.API_path}/${site}/remove`, {})
      .toPromise();
  }

  /**
   * Activate an existing site
   * @param site Name of the site
   * @returns return a promise with achievement or not of the API call
   */
  activateSite(site: string) {
    console.debug(`POST ${this.apiURL}/${this.API_path}/${site}/activate`);
    return this.httpClient
      .post(`${this.apiURL}/${this.API_path}/${site}/activate`, null)
      .toPromise();
  }

  /**
   * Deactivate an existing site
   * @param site Name of the site
   * @returns return a promise with achievement or not of the API call
   */
  deactivateSite(site: string) {
    console.debug(`POST ${this.apiURL}/${this.API_path}/${site}/deactivate`);
    return this.httpClient
      .post(`${this.apiURL}/${this.API_path}/${site}/deactivate`, null)
      .toPromise();
  }

  /**
   * Get list of site's names
   * @returns return an array with list of site's names
   */
  getSitesNames() {
    console.debug(`GET ${this.apiURL}/${this.API_path}?fields=SITE`);
    return this.httpClient
      .get(`${this.apiURL}/${this.API_path}?fields=SITE`)
      .pipe(map((data) => data["sites"].map((site) => site["SITE"])))
      .toPromise();
  }

  /** Add analytics for direct access (SITE_ACCESS) in Elefight */
  putAnalytics(site: string, uuid: string = null) {
    var sourceType = "SITE_ACCESS";
    if (uuid != null) sourceType = "INTERNAL_ACCESS";
    console.debug(`GET ${environment.redirectUrl}/${site}/${uuid}`);
    return this.httpClient
      .get(`${environment.redirectUrl}/${site}/${uuid}?origin=elefight`)
      .toPromise();
  }
}
