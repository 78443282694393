import { Component, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { RoutesService } from "src/app/core/services/routes.service";
import { SitesService } from "src/app/core/services/sites.service";
import { ALERT_MESSAGE } from "src/app/core/classes/AlertMessage";
import { DatePipe } from "@angular/common";
import { SITE } from "src/app/core/classes/Site";
import Auth from "@aws-amplify/auth";

@Component({
  selector: "site-management",
  templateUrl: "./site-management.component.html",
  styleUrls: [
    "./site-management.component.scss",
    "../sites-generator.scss",
    "../../global-back-office.scss",
    "../../../private.scss",
  ],
  providers: [DatePipe],
})
export class SiteManagementComponent implements OnInit, OnDestroy {
  DATA_IS_LOADED: boolean = false;

  ALERT_MESSAGE: ALERT_MESSAGE = {
    TYPE_OF_MESSAGE: null,
    TYPE_OF_ACTION: null,
    MESSAGE: null,
    IS_DISPLAYED: null,
  };

  site_name_route: string = "";
  currentRoutesParamsSuscribe: Subscription;

  SITE: SITE;
  SITE_LANGUAGES: string = "";

  constructor(
    private router: Router,
    private titleService: Title,
    private datePipe: DatePipe,
    private _routesService: RoutesService,
    private _sitesService: SitesService
  ) {
    this.titleService.setTitle("Elefight - Site Management");
    /** Get params in the URL */
    this.currentRoutesParamsSuscribe =
      this._routesService.currentRoutes.subscribe((routes) => {
        if (routes.PARAMS) {
          if (routes.PARAMS["site"] && routes.PARAMS["site"] != undefined) {
            var site = routes.PARAMS["site"];
            this.site_name_route = site.toUpperCase();
          }
        }
      });
    this.DATA_IS_LOADED = false;
    this.getSite();
  }

  ngOnInit(): void {}

  /**
   * Navigate though components
   * @param route route to reach
   * @param site name of the site
   */
  navigate(route: string, site?: string) {
    this.destroySubscription();
    if (site) {
      const url = this.router.createUrlTree([
        `/admin/${site.toLowerCase()}/${route}`,
      ]);
      window.open(url.toString(), "_blank");
    } else this.router.navigate([`/admin/${route}`]);
  }

  /**
   * Navigate to modification component
   * @param route route to reach
   * @param site name of the site
   */
  navigateToModification(route: string, site: string) {
    this.destroySubscription();
    this.router.navigate([`/admin/${route}/${site.toLowerCase()}/modify`]);
  }

  /**
   * Get information about the site
   */
  getSite() {
    this._sitesService
      .getSite(this.site_name_route)
      .then((value) => {
        //For each information we set it with correct standards
        this.SITE = JSON.parse(JSON.stringify(value));
        console.log(this.SITE);
        // this.SITE["OPTIONS"]["ACTIVATED"] = (this.SITE["OPTIONS"]["ACTIVATED"] == 'true');
        this.SITE["CREATION_DATE"] = this.datePipe.transform(
          new Date(parseInt(this.SITE["CREATION_DATE"])),
          "dd/MM/yyyy"
        );
        if (this.SITE["LAST_ACTIVATION_DATE"] === "0")
          this.SITE["LAST_ACTIVATION_DATE"] = "Never activated";
        else
          this.SITE["LAST_ACTIVATION_DATE"] = this.datePipe.transform(
            new Date(parseInt(this.SITE["LAST_ACTIVATION_DATE"])),
            "dd/MM/yyyy"
          );
        if (this.SITE["EXPIRATION_DATE"] === "0")
          this.SITE["EXPIRATION_DATE"] = "N/A";
        else
          this.SITE["EXPIRATION_DATE"] = this.datePipe.transform(
            new Date(parseInt(this.SITE["EXPIRATION_DATE"])),
            "dd/MM/yyyy"
          );
        this.SITE["OPTIONS"]["MAP_ENABLED"] =
          this.SITE["OPTIONS"]["MAP_ENABLED"] === "true" ? "Yes" : "No";
        this.SITE_LANGUAGES = this.SITE.LANGUAGES.map(function (elem, index) {
          let LANGUAGES = {
            en: { long_language: "English" },
            fr: { long_language: "Français" },
            es: { long_language: "Español" },
            it: { long_language: "Italiano" },
          };
          if (index === 0) {
            return LANGUAGES[elem].long_language + " (default)";
          } else {
            return LANGUAGES[elem].long_language;
          }
        }).join(", ");
        this.DATA_IS_LOADED = true;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /**
   * Activate current site
   * @param site Name of the site
   */
  activateSite(site: string) {
    this._sitesService
      .activateSite(site.toUpperCase())
      .then((res) => {
        this.getSite();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /**
   * Deactivate current site
   * @param site Name of the site
   */
  deactivateSite(site: string) {
    this._sitesService
      .deactivateSite(site.toUpperCase())
      .then((res) => {
        this.getSite();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  /**
   * Remove current site
   * @param site Name of the site
   */
  removeSite(site: string) {
    this.ALERT_MESSAGE.IS_DISPLAYED = true;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = "actionModal";
    this.ALERT_MESSAGE.TYPE_OF_ACTION = "remove";
    this.ALERT_MESSAGE.MESSAGE = `<p>Are you sure you want to remove the site ${site} ?</p>`;
  }

  /**
   * Callback of removeSite() action modal
   */
  callbackActionModal_remove() {
    let promises = [];
    promises.push(
      this._sitesService.deleteSite(this.SITE["SITE"].toUpperCase())
    );

    Promise.all(promises).then((values) => {
      console.log(values);
      if (values[0].error) {
        console.error(values[0].error);
        return;
      }
      this.navigate("sites/sites-management");
    });
  }

  /** Callback to close modal and reset object ALERT_MESSAGE after displaying modal */
  callbackResetModal(ALERT_MESSAGE: ALERT_MESSAGE) {
    this.ALERT_MESSAGE.IS_DISPLAYED = false;
    this.ALERT_MESSAGE.TYPE_OF_MESSAGE = null;
    this.ALERT_MESSAGE.MESSAGE = null;
  }

  /** Destroy all subscription in the component */
  destroySubscription(): void {
    this.currentRoutesParamsSuscribe.unsubscribe();
  }

  ngOnDestroy(): void {
    this.destroySubscription();
  }

  signOut() {
    Auth.signOut();
  }
}
