import { Component } from "@angular/core";

@Component({
  selector: "aac-legale-information",
  templateUrl: "./legale-information.component.html",
  styleUrls: ["./legale-information.component.scss"],
})
export class LegaleInformationComponent {
  constructor() {}
}
