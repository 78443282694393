/** List of mandatory params for each public routes */
export var OPTIONS_ROUTES = [
  {
    ROUTES: "home",
    OPTIONS: ["site"],
  },
  {
    ROUTES: "home-map",
    OPTIONS: ["site"],
  },
  {
    ROUTES: "legal",
    OPTIONS: ["site"],
  },
  {
    ROUTES: "cookie",
    OPTIONS: ["site"],
  },
  {
    ROUTES: "survey",
    OPTIONS: ["site", "id"],
  },
  {
    ROUTES: "more-information",
    OPTIONS: ["site", "id"],
  },
];
